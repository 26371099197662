import { createSlice } from "@reduxjs/toolkit";

export const otherDocsSlice = createSlice({
  name: "uploadedFile",

  initialState: {
    documentImage: {
      frontFile: "",
      backFile: "",
    },
    previewImage: {
      frontImageURL: "",
      backImageURL: "",
    },
    previewDocumentDetail: {
      name: "",
      address: "",
      dob: "",
      reduxGenderValue: "",
      fatherName: "",
    },

    formSixtyDocument: "",
    formSixtyDocumentName: "",
    kycRefId: "",
  },

  reducers: {
    fileUpload: (state, action) => {
      state.documentImage = { ...state.documentImage, ...action.payload };
    },
    previewIamgeURL: (state, action) => {
      state.previewImage = { ...state.previewImage, ...action.payload };
    },
    documentDetail: (state, action) => {
      state.previewDocumentDetail = {
        ...state.previewDocumentDetail,
        ...action.payload,
      };
    },
    kycId: (state, action) => {
      state.kycRefId = action.payload;
    },
    formSixtyDocument: (state, action) => {
      state.formSixtyDocument = action.payload;
    },
    formSixtyDocumentName: (state, action) => {
      state.formSixtyDocumentName = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  fileUpload,
  previewIamgeURL,
  documentDetail,
  kycId,
  formSixtyDocument,
  formSixtyDocumentName,
} = otherDocsSlice.actions;

export default otherDocsSlice.reducer;
