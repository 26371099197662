import React from "react";
import styles from "./BannerImage.module.css";

const BannerImage = (props) => {
  return (
    <div
      className={`flex md:hidden ${styles["banner-image-container"]} ${styles["lato-family"]} `}
    >
      {props.children}
    </div>
  );
};

export default BannerImage;
