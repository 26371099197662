import { React } from "react";
import Backdrop from "@mui/material/Backdrop";

const Loadingoverlay = () => {
  return (
    <div>
      <Backdrop
        open={true}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <lottie-player
          src="/assets/AnimationLoader/hdfcergoLoader.json"
          speed="2.0"
          autoplay
          loop={true}
          style={{ width: "150px", height: "150px", margin: "auto" }}
        ></lottie-player>
      </Backdrop>
    </div>
  );
};

export default Loadingoverlay;
