import { createSlice } from "@reduxjs/toolkit";

export const adhaarRedirectInfo = createSlice({
  name: "AdhaarRedirectInfo",

  initialState: {
    requestID: "",
    panNumber: "",
    aadhaarDigiLockerAddress: "",
    aadhaarDigiLockerUserName: "",
    aadhaarDigiLockerGender: "",
    aadhaarDigiLockerFatherName: "",
    aadhaarDigiLockerDob:""
  },

  reducers: {
    addRequestID: (state, action) => {
      state.requestID = action.payload;
    },
    userPanNumberDetails: (state, action) => {
      state.panNumber = action.payload;
    },
    setDigiLockerAddress: (state, action) => {
      state.aadhaarDigiLockerAddress = action.payload;
    },
    setDigiLockerUserName: (state, action) => {
      state.aadhaarDigiLockerUserName = action.payload;
    },
    setDigiLockerGender: (state, action) => {
      state.aadhaarDigiLockerGender = action.payload;
    },
    setDigiLockerFatherName: (state, action) => {
      state.aadhaarDigiLockerFatherName = action.payload;
    },
    setDigiLockerDob:(state, action)=>{
      state.aadhaarDigiLockerDob = action.payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  addRequestID,
  userPanNumberDetails,
  setDigiLockerAddress,
  setDigiLockerUserName,
  setDigiLockerGender,
  setDigiLockerFatherName,
  setDigiLockerDob,
} = adhaarRedirectInfo.actions;

export default adhaarRedirectInfo.reducer;
