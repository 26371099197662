import React, { useCallback, useEffect, useState } from "react";
import { BiErrorCircle } from "react-icons/bi";
import { useSelector } from "react-redux";

const KYCRejectedBox = ({
  label = "",
  reason = "",
  onClickHandler,
  status,
  onClickResolve,
  kycReferenceId,
}) => {
  return (
    <div className="flex flex-col space-y-5 border border-[#F7BCBE] p-3 rounded-md">
      <div className="space-y-2 ">
        <div className="flex flex-row items-center space-x-2 relative">
          {/* <BiInfoCircle className="text-[#E42125] text-2xl font-bold" /> */}
          {status === "rejected" ? (
            <BiErrorCircle className="text-red-500" />
          ) : (
            <div className="absolute top-0">
              <span className="h-2 w-2 rounded-lg bg-red-500 animate-ping absolute top-2"></span>
              <span className="h-2 w-2 rounded-lg bg-red-500 absolute top-2"></span>
            </div>
          )}

          <p className="font-[600] text-sm pl-1.5">{label}</p>
        </div>
        {status === "rejected" && (
          <div className="bg-[#FCE9E9] rounded-md">
            <p className="text-xs text-[#242424] p-3">
              <mark className="bg-white font-bold bg-opacity-0">Reason</mark> :
              &nbsp;
              {reason}
            </p>
          </div>
        )}
      </div>
      {status === "rejected" && (
        <div className="flex flex-row space-x-4 justify-between">
          {/*eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            id="UseAnotherMethod"
            onClick={() => onClickHandler(kycReferenceId)}
            // href="#"
            className="inline-flex w-3/5 items-center justify-center py-2 px-2 text-sm font-medium text-center text-[#E94D51] bg-transparent rounded-md border border-[#E94D51]  focus:ring-2 focus:outline-none focus:ring-[#E94D51]"
          >
            Use Another Method
          </a>
          {/*eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            id="Resolve"
            // href="#"
            className="inline-flex w-1/3 items-center justify-center py-2 px-2 text-sm font-medium text-center text-white bg-[#E94D51] rounded-md border border-[#E94D51]  focus:ring-2 focus:outline-none focus:ring-[#E94D51]"
            onClick={() => onClickResolve(kycReferenceId)}
          >
            Resolve
          </a>
        </div>
      )}
    </div>
  );
};

const ProceedBox = ({
  onClickProceed = () => {},
  kycReferenceId,
  onClickEditHandler,
  label = "",
  status,
}) => {
  return (
    <div className="relative">
      <div className="absolute top-0">
        {/* <span className="h-2 w-2 rounded-lg bg-red-500 animate-ping absolute top-2"></span> */}
        {/* <span className="h-2 w-2 rounded-lg bg-red-500 absolute top-2"></span> */}
      </div>
      <div className="font-bold text-md ml-4 mb-3">{label}</div>
      <div className="flex flex-row space-x-4 justify-between md:cursor-pointer">
        {/*eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          // href="javascript:void(0)"
          id="Proceed"
          className="inline-flex w-full items-center justify-center py-2 px-3 text-sm font-medium text-center text-white bg-[#E94D51] rounded-md border border-[#E94D51]  focus:ring-2 focus:outline-none focus:ring-[#E94D51]"
          onClick={() => onClickProceed(kycReferenceId)}
        >
          Proceed
        </a>
        {/*eslint-disable-next-line jsx-a11y/anchor-is-valid */}
       {status !== "approved" ?
      <a
      id="Edit"
      className="inline-flex w-full items-center justify-center py-2 px-3 text-sm font-medium text-center text-white bg-[#E94D51] rounded-md border border-[#E94D51]  focus:ring-2 focus:outline-none focus:ring-[#E94D51]"
      onClick={() => onClickEditHandler(kycReferenceId)}>
      Edit
    </a>  : ""
      }  
      </div>
    </div>
  );
};

function KYCInfoCard({
  data = {},
  onClickUseAnotherMethod = () => {},
  onClickResolve = () => {},
  onClickProceed = () => {},
  onClickEdit = () => {},
}) {
  const {
    name,
    status,
    kycRefId: kycReferenceId,
    rejection_reason,
    doi,
    typeOfCompany,
  } = data;
  const [otherDetails, setOtherDetails] = useState("");
  const kycTypeTab = useSelector((state) => state.Tabs.kycTypeTab);

  const switchDataUpdatedBy = useCallback((data) => {
    let returnData = {};
    switch (data.lastServiceWhichUpdated) {
      // "PAN", "VOTERID", "DRIVINGLICENSE", "AADHAAR", "PASSPORT"
      case "GST":
        returnData = data.gst;
        break;
      case "PAN":
        returnData = data.pan ? data.pan : data.corporateDocument;
        break;
      case "VOTERID":
        returnData = data.voterid;
        break;
      case "DRIVINGLICENCE":
        returnData = data.drivingLicence;
        break;
      case "AADHAAR":
        returnData = data.aadhaar;
        break;
      case "PASSPORT":
        returnData = data.passport;
        break;
      case "PARTNERPORTAL":
        returnData = data.partnerPortal;
        break;
      case "CORPORATEDOCUMENT":
        returnData = data.corporateDocument;
        break;
      default:
        returnData = data.pan;
        break;
    }
    return returnData;
  }, []);
  useEffect(() => {
    setOtherDetails(switchDataUpdatedBy(data));
  }, [data, switchDataUpdatedBy]);
  return (
    <div className="p-4 max-w-sm md:max-w-md lg:max-w-xl bg-white rounded-sm border border-[#F7BCBE] shadow-lg">
      <div className="flex flex-col h-[100%] last:mt-auto justify-between gap-5">
        <div className="flex flex-col gap-5">
          <div>
            <div className="flex flex-row items-start justify-between uppercase text-xs text-[#E94D51]">
              <p>Name</p>
              {kycTypeTab === "Corporate" ? <p>DOI</p> : <p>DOB</p>}
            </div>
            <div className="flex flex-row items-start justify-between capitalize text-sm text-[#242424] font-medium">
              <p className="truncate md:max-w-[8rem] max-w-[50%]">
                  {otherDetails?.typeOfProprietor ===  "properietor" || otherDetails?.typeOfProprietor ===  "Proprietor" ? otherDetails?.properietorName : name || otherDetails?.name || "NA"}
                  {/* {name || otherDetails?.name || "NA"} */}
              </p>

              <p>
                {(otherDetails?.dob === "Invalid Date"
                  ? "NA"
                  : otherDetails?.dob || otherDetails?.doi) ||
                  doi ||
                  "NA"}
              </p>
            </div>
          </div>

          <div>
            <div className="flex flex-row items-start capitalize text-sm text-[#E94D51] font-medium">
              <p>Address</p>
            </div>
            <div className="flex flex-row items-start capitalize text-sm text-[#242424] font-medium">
              <p>
                {data?.cin?.permanentAddress.toLowerCase() ||
                  String(otherDetails?.permanentAddress).toLowerCase() ||
                  "NA"}
              </p>
            </div>
          </div>
        </div>
        {status !== "approved" ? (
          <KYCRejectedBox
            label={
              status === "unassigned" ||
              status === "assigned" ||
              status === "incomplete"
                ? "Your KYC is pending for verification and will be informed to you within 24 hrs"
                : status === "rejected"
                ? "Your KYC was rejected"
                : "Your KYC is approved"
            }
            reason={rejection_reason}
            onClickHandler={onClickUseAnotherMethod}
            onClickResolve={onClickResolve}
            status={status}
            kycReferenceId={kycReferenceId}
          />
        ) : (
          <ProceedBox
            // label="Your KYC is approved"
            kycReferenceId={kycReferenceId}
            onClickProceed={onClickProceed}
            onClickEditHandler={onClickEdit}
            status={status}
          />
        )}
      </div>
    </div>
  );
}

export default KYCInfoCard;
