import React from "react";
import PropTypes from "prop-types";
import { BsArrowRight } from "react-icons/bs";
import { MdOutlineLocationOn } from "react-icons/md";
import { Oval } from "react-loader-spinner";
function CommonButton({
  filled = false,
  label = "Default Label",
  arrow = false,
  location = false,
  onClickHandler,
  center = false,
  loading = false,
  id,
  msBlue,
}) {
  return (
    <>
      {filled
        ? FilledButton(
            label,
            arrow,
            onClickHandler,
            location,
            center,
            loading,
            id,
            msBlue
          )
        : BorderButton(
            label,
            arrow,
            onClickHandler,
            location,
            center,
            loading,
            id
          )}
    </>
  );
}

const BorderButton = (
  label,
  arrow,
  onClickHandler,
  location,
  center,
  loading,
  id
) => {
  let conditionalStyle = center ? " justify-center" : " justify-between";
  return (
    <button
      onClick={onClickHandler}
      id={id}
      className={
        "inline-flex h-[44px] w-full items-center  py-2 px-3 text-sm font-medium text-center text-primaryH bg-white rounded-md border border-primaryH  focus:ring-2 focus:outline-none focus:ring-primaryH" +
        conditionalStyle
      }
    >
      {label}

      {arrow && <BsArrowRight />}
      {location && <MdOutlineLocationOn />}
      {loading && (
        <Oval
          height={20}
          width={30}
          color="#ffff"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#ffff"
          strokeWidth={5}
          strokeWidthSecondary={5}
        />
      )}
    </button>
  );
};

const FilledButton = (
  label,
  arrow,
  onClickHandler,
  location,
  center,
  loading,
  id,
  msBlue
) => {
  let conditionalStyle = center ? " justify-center" : " justify-between";
  // let msButtonColor = msBlue
  //   ? "bg-[#113569]"
  //   : "bg-[#E94D51] border-[#E94D51] focus:ring-[#E94D51]";

  return (
    <button
      type="submit"
      onClick={onClickHandler}
      id={id}
      className={
        `inline-flex w-full outline-none items-center py-2 px-3 text-sm font-medium text-center text-white  rounded-md border  focus:outline-none bg-primaryH` +
        conditionalStyle
      }
    >
      {label}

      {arrow && <BsArrowRight />}
      {location && <MdOutlineLocationOn />}
      {loading && (
        <Oval
          height={20}
          width={30}
          color="#ffff"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#ffff"
          strokeWidth={5}
          strokeWidthSecondary={5}
        />
      )}
    </button>
  );
};

export default CommonButton;

CommonButton.propTypes = {
  filled: PropTypes.bool,
  arrow: PropTypes.bool,
  location: PropTypes.bool,
  center: PropTypes.bool,
  loading: PropTypes.bool,
};
