import { apiInstance } from "./networking";

export const submitDocuments = async (query, payload) => {
  const { slectedValue, panNumber, kycRefId, channel, isAadhaarMasked } = query;
  const { data = {} } = await apiInstance.post(
    `primary/upload-document-images?type=${slectedValue}&panCard=${panNumber}&kyc_id=${
      kycRefId || ""
    }&channel=${channel}&isAadhaarMasked=${isAadhaarMasked}`,
    payload
  );
  return data;
};

export const formSixtyUplaodDocument = async (query, payload) => {
  const {
    slectedValue,
    txn_id,
    channel,
    kycRefId: kyc_id,
    isAadhaarMasked,
  } = query;
  const { data = {} } = await apiInstance.post(
    `primary/upload-formSixty-images?txn_id=${txn_id}&channel=${channel}&supporting_document_type=${slectedValue}&kyc_id=${kyc_id}&isAadhaarMasked=${isAadhaarMasked}`,
    payload
  );
  return data;
};
