import React, { useEffect, useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Otherdocsdropdown from "./Otherdocsdropdown";
import CommonButton from "../../../Components/global-components/CommonButton/CommonButton";
import UploadDocumentDrawer from "./UploadDocumentDrawer";
import { useDispatch, useSelector } from "react-redux";
import { toggleButton } from "../../Redux/switchToggleSlice";
import { userPanNumber } from "../../Redux/panCardSlice";
import TextInput from "../../../Components/global-components/CommonTextInput/TextInput";
import { activeStepper } from "../../Redux/stepperSlice";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  fileUpload,
  formSixtyDocument,
  formSixtyDocumentName,
  previewIamgeURL,
} from "../../Redux/otherDocsSlice";
import {
  allowedFileSizeMb,
  gaFunction,
  scrollIntoViewFunc,
} from "../../../Router";
import { useNavigate } from "react-router-dom";
import { optionsValue } from "../../Redux/otherdocsDropDownValueSlice";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import CommonDrawer from "../../../Components/global-components/CommonDrawer/CommonDrawer";
import ErrorBottomDrawer from "../../../Components/global-components/ErrorBottomDrawer/ErrorBottomDrawer";
import { clearUserImageDetails } from "../../Redux/CameraSlice";
import {clearFormSixtyDataState, formSixtyData} from "../../Redux/digitalFormSixtySlice";
import { validatePan } from "../../../services/pan-service";
import Loadingoverlay from "../../../Components/global-components/LoadingOverlay/Loadingoverlay";




const OtherDocsFrame = ({ isMaruti = false }) => {
  const userDetails = useSelector((state) => state.userDetails.userData);
  const { panNumber: reduxPanNumber } = userDetails;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isForm, setIsForm] = useState(false);
  const [panNumber, setPanNumber] = useState(reduxPanNumber);
  const [switchState, setSwitchState] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [panValidationError, setpanValidationError] = useState(null);
  const [isDeclarationChecked, setIsDeclarationChecked] = useState(true);
  const [isDeclarationCheckedError, setIsDeclarationCheckedError] =
    useState("");
  const [isMaskedAadhaarDrawerOpen, setIsMaskedAadhaarDrawerOpen] =
    useState(false);
  const [isFormSixtyConfirmBox, setIsFormSixtyConfirmBox] = useState(false);
  const [errorFormSixty, setErrorFormSixty] = useState("");
  const [msgPopup, setMsgPopup] = useState(null);
   const [isLoading, setIsloading] = useState(false);
  const istoggle = useSelector((state) => state.switchButton.activeSwitch);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formSixty = useSelector(
    (state) => state.uploadedFile.formSixtyDocument
  );
  const formSixtyDocName = useSelector(
    (state) => state.uploadedFile.formSixtyDocumentName
  );
  const frontImageUrl = useSelector(
    (state) => state.uploadedFile.previewImage.frontImageURL
  );

  const frontFileName = useSelector(
    (state) => state.uploadedFile.documentImage.frontFile
  );

  const otherDocsDropDownValue = useSelector(
    (state) => state.dropDownValue.selectedValue
  );

  const formSixtyString = useSelector((state) => state?.digitalFormSixty.formSixtyData.formSixtyString);

  const { digitalForm60Id , selectedDoc}=useSelector((state)=>state.digitalFormSixty.formSixtyData);
    // ADD FOR DEUPED LOGIC IN OVD
    const { mi_u,channelUrl } = useSelector(
      (state) => state.partnerChannelNameSlice.marutiUserDetails
    );
   let txn_id = mi_u || localStorage.getItem("txnId");
   let doc_type = "OVD"

  //  const uploadDrawerHandler = () => {
  //   gaFunction("OVD", "OVD upload clicked");
  //   // if (!istoggle) {
  //   //   if (!formSixty?.length) {
  //   //     scrollIntoViewFunc("form_sixty");
  //   //     return setErrorFormSixty("Please upload form 60 pdf");
  //   //   }
  //   // }
  //   console.log("in 103", panNumber)
  //   let regexPan = /[A-Z]{5}\d{4}[A-Z]{1}/i;
  //   if (!regexPan.test(panNumber) && istoggle) {
  //     // setIsDrawerOpen((prev) => prev);
  //   } else {
  //     setIsDrawerOpen((prev) => !prev);
  //     if (otherDocsDropDownValue === "maskedAadhaar") {
  //       console.log("in 110")
  //       setIsMaskedAadhaarDrawerOpen(true);
  //       setIsDrawerOpen(false);
  //     }
  //   }
  //   if (panNumber?.length !== 10) {
  //     console.log("in 116")
  //     scrollIntoViewFunc("ovd_pan_on");
  //     setErrorMessage("Please provide your Pan Number");
  //   } 
  // };

  // //UPLOAD DOCUMENTATION FUNCTION IS USED FOR DEDUPED AND DOC UPLOAD
  const uploadDrawerHandler = () => {
    gaFunction("OVD", "OVD upload clicked");
    setIsloading(true);
    let regexPan = /[A-Z]{5}\d{4}[A-Z]{1}/i;
    if (panNumber?.length === 10 && regexPan.test(panNumber) && istoggle) {
      const data = JSON.stringify({
        pan: String(panNumber).toUpperCase(),
        txn_id,
        doc_type,
        channel:channelUrl,
      });
      
      // VALIDAET APN API USED FOR VALIDATING PAN & TO CHECK EXISTING PAN 
      validatePan(data).then(function (response) {
        console.log(response);
        setIsloading(false);
        if(response?.message == "PAN validated successfully.") setIsDrawerOpen((prev) => !prev);
        if (response.message == "PAN validation failed") {
          setIsDrawerOpen(false);
          setpanValidationError("Invalid Pan Number");
        }
        // DEDUPESD CONCEPT ADDED IF PAN ALREDY EXIST THEN GO OT SUCCESS PAGE
        if (response?.message === "KYC Limit exceed") {
          console.log("OVD KYC Limit exceed");
          setMsgPopup(true);
          setIsDrawerOpen(false);
        }
        // PAN ALREDY EXIST IN DB WILL GOT TO SUCCESS PAGE
        if (response?.exists) {
          console.log("deduped working in OVD");
          setIsDrawerOpen(false);
          setMsgPopup(false);
          dispatch(activeStepper(3));
          navigate("/Success", {
            state: {
              kyc_ref_id: response?.kyc_id,
              typeOfKyc: response?.status,
            },
          });
        }
      });
    }else {
    setIsloading(false);
      setIsDrawerOpen((prev) => !prev);
      if (otherDocsDropDownValue === "maskedAadhaar") {
        setIsMaskedAadhaarDrawerOpen(true);
        setIsDrawerOpen(false);
      }
    }

    if (panNumber?.length !== 10) {
      setIsloading(false);
      scrollIntoViewFunc("ovd_pan_on");
      setpanValidationError(null);
      setErrorMessage("Please provide your Pan Number");
    }
  };

  // pan input function
  const panChangeHandler = (value) => {
    let regexPan = /[A-Z]{5}\d{4}[A-Z]{1}/i;
    value=value?.replace(/\s/g,"");
    if (value.length <= 10) {
      setPanNumber(value);
      dispatch(userPanNumber(value));
    }
    setpanValidationError(null);
    if (!regexPan.test(value)) {
      setpanValidationError("Invalid Pan Number");
    }
    if (panNumber?.length >= 0) {
      setErrorMessage(null);
    }
  };

  //upload form 60 handler
  const uploadFormSixtyDrawerHandler = () => {
    gaFunction("OVD", "OVD upload clicked");
    setIsFormSixtyConfirmBox(true);
  };

  // pan switch handler
  const toggleHandler = (e) => {
    dispatch(toggleButton(e.target.checked));
    if (!e.target.checked)
    navigate("/digitalFormSixty", { state: { methodOfKyc: "otherDocs" } });
  };
  const deleteFormSixtyHandler = () => {
    // dispatch(formSixtyDocument(""));
    // dispatch(formSixtyDocumentName(""));
    dispatch(clearFormSixtyDataState());
    navigate("/digitalFormSixty", { state: { methodOfKyc: "otherDocs" } });
  };

  useEffect(() => {
    if (istoggle && frontImageUrl.length && isDeclarationChecked) {
      setIsDeclarationCheckedError("");
      navigate("/uploadDocument");
    }
    if (
      !istoggle &&
      digitalForm60Id.length &&
      frontImageUrl.length &&
      isDeclarationChecked
    ) {
      setIsDeclarationCheckedError("");
      navigate("/uploadDocument");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSixty, frontImageUrl, isDeclarationChecked, istoggle]);

  useEffect(() => {
    if (istoggle && frontImageUrl.length) {
      setIsDeclarationCheckedError("Please accept declaration");
      scrollIntoViewFunc("ovd_dec_checkbox");
    }
    if (!istoggle && formSixty.length && frontImageUrl.length) {
      setIsDeclarationCheckedError("Please accept declaration");
      scrollIntoViewFunc("ovd_dec_checkbox");
    }
  }, [formSixty, frontImageUrl, istoggle]);

  useEffect(() => {
    //reset all ovd data on mount
    dispatch(formSixtyDocument(""));
    dispatch(formSixtyDocumentName(""));
    dispatch(fileUpload({ frontFile: "" }));
    dispatch(
      previewIamgeURL({
        frontImageURL: "",
      })
    );
    dispatch(clearUserImageDetails());
    if(digitalForm60Id =="") {
      dispatch(optionsValue("aadhaar"));
    }else{
      dispatch(optionsValue(selectedDoc));
    }
    setIsDeclarationCheckedError("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let reduxPanValue=reduxPanNumber;
    reduxPanValue=reduxPanValue?.replace(/\s/g,"");
    console.log(reduxPanValue);
    if (reduxPanNumber?.length == 10) {
      setPanNumber(reduxPanNumber);
      dispatch(userPanNumber(reduxPanNumber));
    }
  }, [reduxPanNumber]);
  useEffect(() => {
    dispatch(activeStepper(0));
  }, []);

  function base64ToBlob(base64String, contentType) {
    const byteCharacters = atob(base64String);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
  
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    return new Blob(byteArrays, { type: contentType });
  }


  const openDocPreview=()=>{
    const contentType = 'application/pdf'; 
    const blob = base64ToBlob(formSixtyString, contentType);
    console.log(URL.createObjectURL(blob));
    window.open(URL.createObjectURL(blob));
  }

  function capitalizeFirstLetter(str) {
    if (typeof str !== 'string') {
      return str; // Return the input unchanged if it's not a string
    }
  
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    <>
    {isLoading ? (
        <Loadingoverlay />
      ) : (
   <>
<div className={`h-full w-full md:mt-[${isMaruti ? "0" : "6px"}]`}>
        {!digitalForm60Id ? (
          <div className="flex   gap-[10px]  items-center mt-4 md:h-[8%]">
            <p>Do you have a valid PAN Number?</p>
            <label
              htmlFor="green-toggle"
              className="inline-flex relative items-center mr-5 md:mr-0 cursor-pointer"
            >
              <input
                type="checkbox"
                id="green-toggle"
                className="sr-only peer"
                onChange={toggleHandler}
                checked={istoggle}
              />
              <div className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-white-700 peer-focus:ring-4   peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600 focus:outline-none ring ring-0"></div>
            </label>
          </div>
        ) : (
          ""
        )}
        <div className="md:h-[65%]  overflow-scroll mt-2">
          <div className={`mt-[${isMaruti ? "0" : "0px"}]`}></div>
          <div>
            {istoggle === false ? (
              <div>
                {!digitalForm60Id ? (
                  <div>
                    <a
                      href="/assets/Form60.pdf"
                      target="_blank"
                      className="text-md"
                    >
                      Click <u className="text-blue-600">here</u> to download
                      Form 60,
                    </a>
                    <span className="text-md">
                      &nbsp;Fill details and upload below
                    </span>
                  </div>
                ) : (
                  ""
                )}

                <div className="border-dashed border-2 border-gray-300 p-4 md:w-[95%]">
                  <div className="text-center ">
                    <p className="font-bold lato-famliy">
                      Form60 Generated Successfully
                    </p>
                    <p className="mt-2">
                      <FileCopyIcon />
                      Form60.pdf
                    </p>
                    {/* {formSixty.length > 0 ? (
                      <>
                        <p className="font-bold lato-famliy">
                          File Uploded Successfully
                        </p>
                        <p className="mt-2">
                          <FileCopyIcon />
                          {formSixtyDocName}
                        </p>
                      </>
                    ) : (
                      <>
                        <FileUploadIcon />
                        <p className="font-bold mt-2 " id="form_sixty">
                          Select a pdf file to upload
                        </p>
                        <p className="text-sm mb-1">
                          Allowed file size is {allowedFileSizeMb} MB
                        </p>
                        <p className="opacity-50 text-xs">
                          Supported file format is pdf only
                        </p>
                      </>
                    )} */}
                  </div>
                  <div className="p-2"></div>
                  <div className=" flex justify-center">
                    <div className="w-32">
                      {/* {isForm && (
                        <UploadDocumentDrawer
                          onClose={setIsForm}
                          isFormSixty={true}
                          acceptFileType="application/pdf"
                        />
                      )} */}
                      {/* {formSixty.length > 0 ? (
                        <div className="flex justify-around">
                        <VisibilityIcon 
                         fontSize="large"
                         onClick={openDocPreview}
                          />
                          <CheckCircleIcon
                            fontSize="large"
                            className="text-green-500 "
                          />
                          <DeleteIcon
                            fontSize="large"
                            className="text-red-500"
                            onClick={deleteFormSixtyHandler}
                          />
                        </div> */}
                      {/* {formSixty.length > 0 ? (
                        <div className="flex justify-around">
                        <VisibilityIcon 
                         fontSize="large"
                         onClick={openDocPreview}
                          />
                          <CheckCircleIcon
                            fontSize="large"
                            className="text-green-500 "
                          />
                          <DeleteIcon
                            fontSize="large"
                            className="text-red-500"
                            onClick={deleteFormSixtyHandler}
                          />
                        </div>
                      ) : (
                        <CommonButton
                          center
                          label={"Upload"}
                          onClickHandler={uploadFormSixtyDrawerHandler}
                        />
                      )} */}
                      <div className="flex justify-around">
                        <VisibilityIcon
                          fontSize="large"
                          onClick={openDocPreview}
                        />
                        {/* <CheckCircleIcon
                            fontSize="large"
                            className="text-green-500 "
                          /> */}
                        <DeleteIcon
                          fontSize="large"
                          className="text-red-500"
                          onClick={deleteFormSixtyHandler}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className={`mt-${isMaruti ? "0" : ""}`}>
                  {/* <div className="flex flex-row justify-between items-center">
                    <p>Do you have a valid PAN Number?</p>
                    <label
                      htmlFor="green-toggle"
                      className="inline-flex relative items-center mr-5 cursor-pointer"
                    >
                      <input
                        type="checkbox"
                        id="green-toggle"
                        className="sr-only peer"
                        onChange={toggleHandler}
                        checked={istoggle}
                      />
                      <div className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-white-700 peer-focus:ring-4   peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600 focus:outline-none ring ring-0"></div>
                    </label>
                  </div> */}
                  {/* <div cl assName="p-2"></div> */}
                  {istoggle ? (
                    <div className="md:w-[65%]" id="ovd_pan_on">
                      <label
                        htmlFor="first_name"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-700"
                      >
                        PAN No.
                      </label>
                      <TextInput
                        value={panNumber}
                        id="otherdocs_panNumber"
                        onChange={(e) => {
                          panChangeHandler(e.target.value.toUpperCase());
                        }}
                        placeholder="PAN number"
                        required
                      />
                      {panValidationError && (
                        <p className="text-red-500 text-sm">
                          {panValidationError}
                        </p>
                      )}
                      {errorMessage && istoggle === true && (
                        <p className="text-red-500 font-medium">
                          {errorMessage}
                        </p>
                      )}
                    </div>
                  ) : (
                    <div>
                      <div>
                        <a
                          href="/assets/Form60.pdf"
                          target="_blank"
                          className="text-md"
                        >
                          Click <u className="text-blue-600">here</u> to
                          download Form 60,
                        </a>
                        <span className="text-md">
                          &nbsp;Fill details and upload below
                        </span>
                      </div>
                      <div className="border-dashed border-2 border-gray-300 p-4">
                        <div className="text-center ">
                          {formSixty.length > 0 ? (
                            <>
                              <p className="font-bold lato-famliy">
                                File Uploded Successfully
                              </p>
                              <p className="mt-2">
                                <FileCopyIcon />
                                {formSixtyDocName}
                              </p>
                            </>
                          ) : (
                            <>
                              <FileUploadIcon />
                              <p className="font-bold mt-2 " id="form_sixty">
                                Select a pdf file to upload
                              </p>
                              <p className="text-sm mb-1">
                                Allowed file size is {allowedFileSizeMb} MB
                              </p>
                              <p className="opacity-50 text-xs">
                                Supported file format is pdf only
                              </p>
                            </>
                          )}
                        </div>
                        <div className="p-2"></div>
                        <div className=" flex justify-center">
                          {/* <div className="w-32 ">
                            {isForm && (
                              <UploadDocumentDrawer
                                onClose={setIsForm}
                                isFormSixty={true}
                                acceptFileType="application/pdf"
                              />
                            )}
                            {formSixty.length > 0 ? (
                              <div className="flex justify-center">
                                <CheckCircleIcon
                                  fontSize="large"
                                  className="text-green-500"
                                />
                              </div>
                            ) : (
                              <CommonButton
                                label={"Upload"}
                                id={"upload_docs_btn"}
                                onClickHandler={uploadFormSixtyDrawerHandler}
                                center
                              />
                            )}
                          </div> */}
                        </div>
                      </div>
                      {/* {errorFormSixty && (
                        <p className="mt-2 text-red-500 text-md">
                          {errorFormSixty}
                        </p>
                      )} */}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          <div className="p-3"></div>
          <div className="border-dashed border-2 border-gray-300 p-4 md:w-[95%] w-full">
            <div className="text-center short:h-[10%]">
              <FileUploadIcon />
              <p className="font-bold mt-2">
                {digitalForm60Id
                  ? `Please Upload ${capitalizeFirstLetter(
                      otherDocsDropDownValue
                    )} file.`
                  : "Select a file to upload"}
              </p>
              <p className="text-sm mb-1">
                Allowed file size is {allowedFileSizeMb} MB
              </p>
              <p className="opacity-50 text-xs">
                Supported file formats are png, jpeg, heic and jpg.
              </p>
              {frontFileName?.length > 0 && (
                <p className="mt-2">
                  <FileCopyIcon />
                  {frontFileName}
                </p>
              )}
            </div>
            <div className="p-2"></div>
            <div className="flex items-center justify-between gap-10">
              {digitalForm60Id ? (
                ""
              ) : (
                <React.Fragment>
                  <div className="w-[60%]">
                    <Otherdocsdropdown />
                    {isMaskedAadhaarDrawerOpen && (
                      <ErrorBottomDrawer
                        onClickHandler={() => {
                          setIsMaskedAadhaarDrawerOpen(false);
                          setIsDrawerOpen(true);
                        }}
                        info
                        errorMessage={
                          "Kindly upload masked image of aadhaar only."
                        }
                      />
                    )}
                  </div>{" "}
                </React.Fragment>
              )}

              {/* <div className="w-[40%] flex"> */}
              <div
                className={digitalForm60Id ? "w-[100%] flex" : "w-[40%] flex"}
              >
                {isDrawerOpen && (
                  <UploadDocumentDrawer
                    onClose={setIsDrawerOpen}
                    isNavigate={isDeclarationChecked}
                    isPdfAllowed={false}
                  />
                )}
                <CommonButton
                  label={frontFileName?.length ? "File uploaded" : "Upload"}
                  onClickHandler={uploadDrawerHandler}
                  center
                  id={"upload_btn"}
                />
                {isMaskedAadhaarDrawerOpen && digitalForm60Id && (
                  <ErrorBottomDrawer
                    onClickHandler={() => {
                      setIsMaskedAadhaarDrawerOpen(false);
                      setIsDrawerOpen(true);
                    }}
                    info
                    errorMessage={"Kindly upload masked image of aadhaar only."}
                  />
                )}
                {msgPopup && (
                  <ErrorBottomDrawer
                    info
                    // errorMessage="We were unable to create your KYC due to your limit being exceeded."
                    errorMessage="We are unable to create New pehchaan ID as pehchaan ID already exist for the desired PAN, request to use same.
                                  To find existing Pehchaan ID already created for the PAN, use alternative pehchaan creation option(Aadhaar/ OVD)."
                    onClickHandler={() => setMsgPopup(false)}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="w-full mt-4" id="ovd_dec_checkbox">
            {isDeclarationCheckedError && (
              <div className="mb-1 text-red-500 text-md">
                {isDeclarationCheckedError}
              </div>
            )}
            {/* <div className="flex">
              <input
                // id="ovd_dec_checkbox"
                type="checkbox"
                value={isDeclarationChecked}
                checked={isDeclarationChecked}
                className="w-5 h-5  rounded border-gray-300 text-primaryH ring-0 focus:ring-0"
                onClick={(e) => {
                  setIsDeclarationChecked(e.target.checked);
                }}
              />
              <label
                htmlFor="link-checkbox"
                className="ml-2 text-sm font-medium text-black "
              >
                I hereby provide my consent to HDFC ERGO General Insurance
                Company Limited to retrieve/ verify my KYC documents like
                Address /ID proof and photograph, from the CERSAI portal against
                my PAN No. I understand that the said KYC documents shall be
                relied upon by the Company for processing my policy/refunds/
                Claims.
              </label>
            </div> */}
          </div>
        </div>
        <div className="p-2 md:p-0"></div>
        <footer className="md:h-[20%] text-sm">
          <p>Acceptable Documents (Any one) :</p>
          <div className="flex flex-row gap-10">
            <div className="flex flex-col text-xs">
              <li>Aadhaar card / masked aadhaar </li>
              <li>Passport</li>
            </div>
            <div className="flex flex-col text-xs">
              <li>Driving License</li>
              <li>Voters’ ID Card</li>
            </div>
          </div>
          <div className="p-2"></div>
          <p className="text-sm md:hidden mb-3 ">
            <b>
              *Kindly keep the original document handy to be used for
              verification in the next step.*
            </b>
          </p>
        </footer>
      </div>
      <Dialog
        open={isFormSixtyConfirmBox}
        onClose={() => {
          setIsForm(false);
          setIsFormSixtyConfirmBox(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Have you filled the form correctly?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsFormSixtyConfirmBox(false)}
            color="error"
            id="alert-ovd-no-button"
          >
            No
          </Button>
          <Button
            onClick={() => {
              setIsFormSixtyConfirmBox(false);
              setIsForm(true);
            }}
            color="success"
            autoFocus
            id="alert-ovd-yes-button"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
   </>
      )
    }
     
    </>
  );
};

export default OtherDocsFrame;