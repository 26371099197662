import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { makeStyles } from "@mui/styles";
import CommonButton from "../../../Components/global-components/CommonButton/CommonButton";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  root: {
    "& .MuiPaper-root": {
      borderTopLeftRadius: 30,
      borderTopRightRadius: 30,
      "@media (min-width: 500px)": {
        width: "50%",
        margin: "auto",
      },
    },
  },
});

const PreviewDocument = (props) => {
  const { imgUrl, closePreview = () => {} } = props;
  const [state, setState] = useState({
    bottom: true,
  });

  const slectedValue = useSelector(
    (state) => state.dropDownValue.selectedValue
  );

  const { kycTypeSelected } = useSelector(
    (state) => state.userDetails.userData
  );

  const closeHandler = () => {
    setState({ bottom: false });
    closePreview();
  };

  const classes = useStyles();

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
      }}
      role="presentation"
    >
      <div className="p-4">
        <label className="capitalize font-bold">
          {kycTypeSelected === "Corporate" ? "" : slectedValue}
        </label>
        <div className="p-2"></div>
        <img src={imgUrl} alt="img" width="600px" height="400px" />
        <div className="p-4"></div>
        <CommonButton
          label="Close"
          center
          filled
          onClickHandler={closeHandler}
        />
      </div>
    </Box>
  );

  return (
    <div>
      {["bottom"].map((anchor, index) => (
        <React.Fragment key={Math.random() + index}>
          <Drawer anchor={anchor} open={state[anchor]} classes={classes}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
};

export default PreviewDocument;
