import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CommonButton from "../Components/global-components/CommonButton/CommonButton";
import CommonDropDown from "../Components/global-components/CommonDropDown/CommonDropDown";
import CorporateUpload from "../Components/global-components/CorporateUpload/CorporateUpload";
import BannerImage from "../Components/login-components/banner-image/BannerImage";
import BottomCard from "../Components/login-components/bottom-card/BottomCard";
import LogoContainer from "../Components/login-components/logo-container/LogoContainer";
import MsHome from "../Components/Maruti-Suzuki-components/MsHome";
import {
  coFileUpload,
  coIsDocumentPageRender,
  corporateDocumentType,
  deleteAllDocument,
} from "../Screens/Redux/corporateSlice";
import CorporateUploadFile from "./CorporateUploadFile";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";
import PreviewDocument from "../Screens/KYCHomeScreen/Frames/PreviewDocument";
import DeleteIcon from "@mui/icons-material/Delete";
import truncate from "truncate";
import { coUploadDocument } from "../services/corporate-upload-service";
import Loadingoverlay from "../Components/global-components/LoadingOverlay/Loadingoverlay";
import { Password } from "@mui/icons-material";
import {
  globalError,
  showGlobalError,
} from "../Screens/Redux/globalErrorSlice";
import {clearFormSixtyDataState, formSixtyData} from "../Screens/Redux/digitalFormSixtySlice";

import axios from "axios";
import { coAddNewKycIdReducer } from "../Screens/Redux/corporateSlice";

const supportingDocs = {
  CompanyLtd: [
    "Select",
    "Power of Attorney",
    "Undertaking by Authorised Signatory",
  ],
  PartnershipFirm: ["Select", "Power of Attorney", "Letter of Authorisation"],
  Trust: ["Select", "Authorisation Letter of Trustees"],
  UnincorporatedInstitution: [
    "Select",
    "Power of Attorney",
    "Resolution of Managing Body",
  ],
  LLP: ["Select", "Letter of Authority"],
  SocietyorEducationalInstitution: ["Select", "Power of Attorney"],
  GovernmentEntity: ["Select", "Request for Proposal", "Request for Quote"],
  ForeignEmbassy: ["Select", "Power of Attorney"],
};

const documentName = {
  CompanyLtd: [
    {
      title: "Certificate of Incorporation",
    },
    {
      title: "Pan Card of Entity",
    },
    {
      title: "Article of Association (AoA)",
    },
    {
      title: "Memorandum of Assocication (MoA)",
    },
    {
      title: "List of Directors",
    }
  ],
  PartnershipFirm: [
    { title: "CA Registration Certificate" },
    { title: "Partnership Deed" },
    { title: "Pan Card" },
    { title: "List of Partners" },
  ],
  Trust: [{ title: "Trust Deed" }, { title: "Pan Card" }, { title: "List of Trustees" }],
  UnincorporatedInstitution: [
    { title: "Pan Card" },
    { title: "Authorisation Letter of Trustees" },
  ],
  Proprietorship: [
    { title: "Proof of Identity and address of proprietor" },
    { title: "Proof of Address of proprietorship" },
    { title: "Pan Card / Form 60 of Proprietor" },
  ],
  HUF: [
    { title: "Photograph" },
    { title: "Pan Card " },
    { title: "Proof of Identity" },
    { title: "Proof of Residence" },
  ],
  LLP: [
    { title: "Certificate of Incorporation" },
    { title: "Agreement Copy" },
    { title: "Pan Card" },
  ],
  SocietyorEducationalInstitution: [
    { title: "Registration Certificate" },
    { title: "Pan Card" },
  ],

  ForeignEmbassy: [{ title: "Embassy Registration Document / Letter" }],
};
const CorporateDocumentUpload = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [supportingDocsVal, setSupportingDocsVal] = useState("");
  const [isUploadClicked, setIsUploadClicked] = useState(false);
  const [docName, setDocName] = useState("");
  const [isUploaded, setIsUploaded] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { state } = useLocation();
  const { isRouteAllowed,isFormSixtyFilled } = state || {};
  const errorMessage = useSelector(
    (state) => state.corporateSlice.errorMessage
  );
  const { channelUrl, mi_u } = useSelector(
    (state) => state.partnerChannelNameSlice.marutiUserDetails
  );
  const selectedValue = useSelector(
    (state) => state.corporateSlice.selectedValue
  );
  const sendThisValueToBackend = useSelector(
    (state) => state.corporateSlice.sendThisValueToBackend
  );
  let dropDownValue = selectedValue?.replace(/[ ]+/g, "");

  const proprietorRadioValue = useSelector(
    (state) => state.corporateSlice.proprietorRadioValue
  );
  const documentType = useSelector(
    (state) => state.corporateSlice.allDocumentName
  );

  const anotherMode = useSelector((state) => state.corporateSlice.anotherMode);
  const properietorName = useSelector(
    (state) => state.corporateSlice.properietorName
  );
  const { phoneNumber } = useSelector((state) => state.userDetails.userData);

  const typeOfProprietor = useSelector((state) => state.corporateSlice.proprietorRadioValue);
  
  const formSixtyString = useSelector((state) => state?.digitalFormSixty.formSixtyData.formSixtyString);
  let {userFirstName, userMiddleName, userSurName,fullNameForm60=`${userFirstName} ${userMiddleName} ${userSurName}`,dateOfBirth:doiForm60,roomNo,floorNo,PremisesName,BlockNo,area,town:permanentCityForm60,pinCode:permanentPincodeForm60,states:permanentStateForm60,mobileNumber:mobileNumberForm60,permanentAddressForm60=`${roomNo,floorNo,PremisesName,BlockNo,area}`, } = useSelector((state) => state?.digitalFormSixty.formSixtyData)

  const {
    name,
    panNumber,
    doi,
    permanentAddress,
    permanentCity,
    permanentState,
    permanentPincode,
    correspondenceAddressLineOne,
    correspondenceAddressLineTwo,
    correspondenceState,
    correspondenceCity,
    correspondencePincode,
  } = useSelector((state) => state.corporateSlice.userData);

  const {
    isAryaLiteServiceUsed,
  } = useSelector((state) => state.corporateSlice.panUserdata);

  const radioValue = useSelector((state) => state.corporateSlice.radioValue);
  const ckycNumber = useSelector((state) => state.corporateSlice.ckycNumber);
  const cinNumber = useSelector((state) => state.corporateSlice.cinNumber);

  const {
    fullName,
    dob,
    permanentAddress: pAddress,
    permanentCity: pCity,
    permanentState: pState,
    permanentPincode: pPincode,
  } = useSelector((state) => state.corporateSlice.panUserdata);


  function changedateformat(val) {
    if(val){
    const myArray = val.split("-");
    let year = myArray[0];
    let month = myArray[1];
    let day = myArray[2];
    let formatteddate = day + "-" + month + "-" + year;
    return formatteddate;
    }else return ""
  }

  

  const dateOfIncorporation = useSelector(
    (state) => state.corporateSlice.dateOfIncorporation
  );
  const { location } = useSelector((state) => state.userDetails.geoLocation);
  const kycId = useSelector((state) => state.corporateSlice.addNewKycId);


  const preLoadDob = useSelector((state) => state.corporatePreloadData.preloadData.doi);
  const preLoadpan = useSelector((state) => state.corporatePreloadData.preloadData.pan)

  
  useEffect(() => {
    if(Object.keys(documentType).length>0){
    for (let key in documentType) {
      if (key === docName) {
        setIsUploaded(documentType[key]);
      }
    }
  }
  }, [documentType, docName]);

  const deleteHandler = (title) => {
    if (title === documentType[title]?.title) {
      dispatch(coFileUpload({ [title]: {} }));
    }
  };
  // fileUploadValidator

  const compareValue = documentName[dropDownValue]?.map((item) =>
    item?.title?.replace(/[ ]+/g, "")
  );

  let passOrFail = [];

  function fileUploadValidator() {
    if (dropDownValue !== "GovernmentEntity") {
      compareValue.map((item) => {
        if (
          (item === documentType[item]?.title && anotherMode) ||
          item === documentType[item]?.title ||
          (!errorMessage &&
            !anotherMode &&
            !isFormSixtyFilled&&
            selectedValue === "Proprietorship" &&
            compareValue[0] === "ProofofIdentityandaddressofproprietor")
        ) {
          return passOrFail.push(true);
        } else {
          return passOrFail.push(false);
        }
      });
    } else {
      passOrFail.push(true);
    }
  }
  const checkPassOrFail = (i) => i === true;
  let fileConverter = async (image, imageName) => {
    let ConversionToFile = null;
    let file = null;
    await fetch(image)
      .then((res) => res.blob())
      .then((blob) => {
        ConversionToFile = blob;
        let blobFile = new File([blob], imageName, {
          type: ConversionToFile.type,
        });
        file = blobFile;
      });
    return file;
  };
  //co meta data update
  const coMetaDataUpdate = (kycIdParam) => {
    setIsLoading(true);
    let data = JSON.stringify({
      channel: channelUrl || "",
      txnId: mi_u || "",
      kyc_id: kycIdParam || kycId || "",
      permanentAddress: permanentAddress  || "",
      permanentPincode:permanentPincode || "",
      permanentState: permanentState || "",
      permanentCity:permanentCity || "",
      correspondenceAddress:correspondenceAddressLineOne? correspondenceAddressLineOne+correspondenceAddressLineTwo :permanentAddress,
      correspondenceCity:correspondenceCity ||permanentCity || "" ,
      correspondencePincode:correspondencePincode || permanentPincode || "",
      correspondenceState:correspondenceState || permanentState || "",
      isAryaLiteServiceUsed
    });
    let config = {
      method: "patch",
      url: `${process.env.REACT_APP_BASE_URL}/customer/update-customer-meta`,
      headers: {
        "Content-Type": "application/json",
        txnId: localStorage.getItem("txnId") === "null" ?? null,
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        if (response?.data?.success === true) {
          setIsLoading(false);
          navigate("/Success", {
            state: {
              kyc_ref_id: response?.data?.data?.kyc_id,
              successUrl: response?.data?.data?.success_url,
              isRouteAllowed: true,
              typeOfKyc: "ovd",
            },
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };
  const contineHandler = async () => {
    fileUploadValidator();
    let data = new FormData();
    data.append("mobileNumber", phoneNumber || mobileNumberForm60);
    data.append("txnId", mi_u || "");
    data.append("typeOfCompany", sendThisValueToBackend);
    data.append("fullName", fullName || name || fullNameForm60);
    data.append("properietorName", properietorName);
    data.append("kyc_id", kycId || "");
    data.append("doi", dob || doi || dateOfIncorporation || changedateformat(preLoadDob) || changedateformat(doiForm60) ||"");
    data.append("pan", panNumber || preLoadpan ||"");
    data.append("permanentAddress", permanentAddress || pAddress || permanentAddressForm60);
    data.append("permanentCity", permanentCity || pCity|| permanentCityForm60);
    data.append("permanentState", permanentState || pState || permanentStateForm60);
    data.append("permanentPincode", permanentPincode || pPincode || permanentPincodeForm60);
    data.append("channel", channelUrl);
    data.append("location", location);
    data.append("correspondenceAddressOne", correspondenceAddressLineOne);
    data.append("correspondenceAddressTwo", correspondenceAddressLineTwo);
    data.append("correspondenceState", correspondenceState);
    data.append("correspondenceCity", correspondenceCity);
    data.append("correspondencePincode", correspondencePincode);
    data.append("ckycNumber", ckycNumber || "");
    data.append("typeOfProprietor", typeOfProprietor || "");
    data.append("isFormSixty",Boolean(isFormSixtyFilled));
    for (let key in documentType) {
      const files = await fileConverter(
        documentType[key]?.FileImage,
        documentType[key]?.FileName
      );
      data.append(key.replace(/[^a-zA-Z ]/g, ""), files);
    }

    if (
      (passOrFail.every(checkPassOrFail) &&
        supportingDocsVal.replace(/[ ]+/g, "") ===
        documentType[supportingDocsVal.replace(/[ ]+/g, "")]?.title) ||
      (selectedValue === "HUF" && passOrFail.every(checkPassOrFail)) ||
      (selectedValue === "Proprietorship" && passOrFail.every(checkPassOrFail))
    ) {
      setIsLoading(true);
      coUploadDocument(data)
        .then((response) => {
          if (response?.success === true) {
            setIsLoading(false);
            if (proprietorRadioValue === "Proprietor") {
              return coMetaDataUpdate(response?.data?.kyc_id);
            }
            if (selectedValue === "Foreign Embassy") {
              return coMetaDataUpdate(response?.data?.kyc_id);
            }
            if (proprietorRadioValue === "Proprietorship") {
              return coMetaDataUpdate(response?.data?.kyc_id);
            }
            navigate("/authorisedDocument", {
              state: {
                kyc_ref_id: response?.data?.kyc_id,
                isRouteAllowed: true,
                typeOfKyc: "ovd",
              },
            });
            dispatch(coAddNewKycIdReducer(response?.data?.kyc_id || ""));
          }
        })
        .catch(function (error) {
          setIsLoading(false);
        });
    } else {
      dispatch(showGlobalError("Kindly upload all document."));
      setIsLoading(false);
    }
  };
  useEffect(() => {
    //on mount image should delete
    dispatch(deleteAllDocument());
  }, []);

  useEffect(() => {
    // disable browser button
    return () => {
      setTimeout(() => {
        window.history.forward(); 
      }, 0);
    };
  }, []);

  useEffect(() => {
    // not showing html text on md screen on document upload
    dispatch(coIsDocumentPageRender(true));
  }, []);

  useEffect(() => {
    if (!state) navigate("/login");
  }, [navigate, state]);

  useEffect(() => {
    const files={}
  if(isFormSixtyFilled){
    const contentType = 'application/pdf'; 
    const blob = base64ToBlob(formSixtyString, contentType);
    files['PanCard/Form60ofProprietor'] = {
    FileName: "Form 60.pdf",
    FileImage: URL.createObjectURL(blob),
    // FileImage:"blob:http://localhost:3000/9c1fe8c4-2b92-4c02-9053-05e28b46b090",
    title: "PanCard/Form60ofProprietor",
  };
  dispatch(coFileUpload(files));
  }
  }, [isFormSixtyFilled])
  

  
  function base64ToBlob(base64String, contentType) {
    const byteCharacters = atob(base64String);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
  
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    return new Blob(byteArrays, { type: contentType });
  }

  // const openDocPreview=()=>{
  //   const contentType = 'application/pdf'; 
  //   const blob = base64ToBlob(formSixtyString, contentType);
  //   console.log(URL.createObjectURL(blob));
  //   window.open(URL.createObjectURL(blob));
  // }

  

  const deleteFormSixtyHandler = () => {
    // dispatch(formSixtyDocument(""));
    // dispatch(formSixtyDocumentName(""));
    dispatch(clearFormSixtyDataState());
    navigate("/digitalFormSixty", { state: { methodOfKyc: isFormSixtyFilled?"corporateProprietor":"otherDocs" } });
  };

  return (
    <>
      {isLoading ? (
        <Loadingoverlay />
      ) : (
        <>
          {isRouteAllowed && (
            <div className="h-full flex flex-col" id="cop-upload-document">
              <MsHome>
                <BannerImage>
                  <LogoContainer />
                </BannerImage>
                <div className="bg-[#FCE9E9] h-full">
                  <BottomCard>
                    <div
                      className="md:w-[100%] md:h-[100%] mt-0 md:mt-0 overflow-scroll"
                      id="uploadDiv"
                    >
                      <div className=" overflow-scroll">
                        {dropDownValue !== "GovernmentEntity" && (
                          <>
                            <p className="font-bold">KYC Documents of Entity</p>
                            <label className="text-sm opacity-70">
                              Mandatory Documents
                            </label>
                            <p className="text-sm opacity-70">
                              Supported file formats are pdf.Size Limit 10 MB
                              each.
                            </p>
                            {documentName[dropDownValue].map((item, index) => (
                              <>
                                {item?.title?.replace(/[ ]+/g, "") ===
                                  documentType[item?.title?.replace(/[ ]+/g, "")]
                                    ?.title ? (
                                  <div className="flex border justify-between  p-4 mt-2">
                                    <div>
                                      <FileCopyIcon />
                                    </div>
                                    <div>
                                      <span>
                                        {truncate(
                                          documentType[
                                            item?.title?.replace(/[ ]+/g, "")
                                          ]?.FileName,
                                          10
                                        )}
                                      </span>
                                    </div>
                                    <div className="flex flex-row gap-5">
                                      <a
                                        rel="noreferrer"
                                        target={"_blank"}
                                        href={
                                          documentType[
                                            item?.title?.replace(/[ ]+/g, "")
                                          ]?.FileImage
                                        }
                                      >
                                        <VisibilityIcon />
                                      </a>
                                      {/* <DownloadIcon onClick={""} /> */}
                                      <DeleteIcon
                                        onClick={() => {
                                          isFormSixtyFilled && item?.title?.replace(/[ ]+/g, "") === "PanCard/Form60ofProprietor"
                                           ? deleteFormSixtyHandler():
                                          deleteHandler(
                                            documentType[
                                              item?.title?.replace(/[ ]+/g, "")
                                            ]?.title
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                ) : !errorMessage &&
                                  !anotherMode &&
                                  !isFormSixtyFilled&&
                                  // if we are not  getting an error and not comming from another mode in Proprietor then run this code
                                  dropDownValue === "Proprietorship" &&
                                  (item.title === "Proof of Identity and address of proprietor" ||
                                    item.title ===
                                    "Pan Card / Form 60 of Proprietor") ? null : (
                                  <CorporateUpload
                                    title={item?.title}
                                    supportedFile={"Supported file pdf only."}
                                    onClickHandler={() => {
                                      setIsUploadClicked(true);
                                      setDocName(
                                        item?.title?.replace(/[ ]+/g, "")
                                      );
                                    }}

                                  />
                                )}
                              </>
                            ))}
                          </>
                        )}
                        {isUploadClicked && (
                          <CorporateUploadFile
                            onClose={setIsUploadClicked}
                            title={docName}
                            acceptFileType="application/pdf"
                            isFormSixty={docName==="PanCard/Form60ofProprietor"&&isFormSixtyFilled}
                          />
                        )}
                        {dropDownValue !== "HUF" &&
                          dropDownValue !== "Proprietorship" && (
                            <div className="mt-2 w-full]">
                              <p className="text-sm opacity-70">
                                Other Supporting Document being uploaded is:
                              </p>
                              <div className="md:w-[89%]">
                                <CommonDropDown
                                  options={supportingDocs[dropDownValue].map(
                                    (title) => title
                                  )}
                                  width={"md:w-[95%]"}
                                  selected={supportingDocsVal}
                                  setSelected={setSupportingDocsVal}
                                />
                              </div>
                              {supportingDocsVal.replace(/[ ]+/g, "") ===
                                documentType[
                                  supportingDocsVal.replace(/[ ]+/g, "")
                                ]?.title ? (
                                <div className="flex border justify-between  p-4">
                                  <div>
                                    <FileCopyIcon />
                                  </div>
                                  <div>
                                    <span>
                                      {truncate(
                                        documentType[
                                          supportingDocsVal.replace(/[ ]+/g, "")
                                        ]?.FileName,
                                        10
                                      )}
                                    </span>
                                  </div>
                                  <div className="flex flex-row gap-5">
                                    <a
                                      rel="noreferrer"
                                      target={"_blank"}
                                      href={
                                        documentType[
                                          supportingDocsVal.replace(/[ ]+/g, "")
                                        ]?.FileImage
                                      }
                                    >
                                      <VisibilityIcon />
                                    </a>
                                    {/* <DownloadIcon onClick={""} /> */}
                                    <DeleteIcon
                                      onClick={() => {
                                        deleteHandler(
                                          documentType[
                                            supportingDocsVal.replace(
                                              /[ ]+/g,
                                              ""
                                            )
                                          ]?.title
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                              ) : (
                                supportingDocsVal && (
                                  <CorporateUpload
                                    title={supportingDocsVal}
                                    supportedFile="Supported file pdf only."
                                    onClickHandler={() => {
                                      setIsUploadClicked(true);
                                      setDocName(
                                        supportingDocsVal.replace(/[ ]+/g, "")
                                      );
                                    }}
                                  />
                                )
                              )}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="md:w-[300px]">
                      <CommonButton
                        label="Continue"
                        onClickHandler={contineHandler}
                        filled
                        arrow
                      />
                    </div>
                  </BottomCard>
                </div>
              </MsHome>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default CorporateDocumentUpload;
