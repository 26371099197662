import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    preloadData: {
        pan: "",
        cin: "",
        ckyc: "",
        doi: "",
        gstNumber:""
    }
}

export const corporatePreloadData = createSlice({
    name: "corporatePreloadData",
    initialState,
    reducers: {
        coPreloadData: (state, action) => {
            state.preloadData = { ...state.preloadData, ...action.payload }
        }
    }
})

export const { coPreloadData } = corporatePreloadData.actions;

export default corporatePreloadData.reducer