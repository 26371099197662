import React from "react";
import { useSelector } from "react-redux";

const MsFooter = () => {
  // const getStyle = () => {
  //   if (isActive) {
  //     return "bg-[#E9F1FB]";
  //   }
  //   return "bg-white";
  // };
  const { channelUrl } = useSelector(
    (state) => state.partnerChannelNameSlice.marutiUserDetails
  );

  const getStyles = () => {
    if (channelUrl === "maruti") {
      return "bg-[#113569] ";
    } else {
      return "bg-[#f7f7f7]";
    }
  };

  const textWhite = () => {
    if (channelUrl === "maruti") {
      return " text-white";
    } else {
      return "text-black opacity-50";
    }
  };
  return (
    <footer>
      <div className={`h-[48px]  shadow-inner ${getStyles()}`}>
        <div>
          <p className="text-black flex items-center justify-center pt-2 pb-2 font-medium">
            <span className={` ${textWhite()}`}> Powered by </span>
            &nbsp;
            <span className="bg-white text-black pl-1 pr-1 pt-0.5 pb-0.5 rounded-sm font-medium  shadow-md">
              Ctrl
            </span>
            &nbsp;
            <span className="bg-red-500 text-white pl-1 pr-1 pt-0.5 pb-0.5 rounded-sm shadow-md">
              N
            </span>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default MsFooter;
