import { async } from "q";
import { apiInstance } from "./networking";

export const getCorporateCustomerAllKyc = async (payload) => {
  const { data = {} } = await apiInstance.get(
    `customer/corp-customer-all-kyc`,payload
  );
  return data;
};

export const coKycSearch  = async (payload) => {
  const { _id } = payload;
  const {data = {}} = await apiInstance.get(`customer/search-corp-kyc?kycRefId=${_id.toUpperCase()}`)
  return data;
}