import { createSlice } from "@reduxjs/toolkit";

export const stepperSlice = createSlice({
  name: "stepper",

  initialState: {
    activeStepper: 0,
  },
  reducers: {
    activeStepper: (state, action) => {
      state.activeStepper = action.payload;
    },
  },
});

export const { activeStepper } = stepperSlice.actions;

export default stepperSlice.reducer;
