import React from "react";

const HiddenWrapper = ({ width, children }) => {
  return (
    <div
      className={`hidden md:block m-0 p-0 md:flex-initial md:w-[${
        width ? "38%" : ""
      }]`}
    >
      {children}
    </div>
  );
};

export default HiddenWrapper;
