import { createSlice } from "@reduxjs/toolkit";

export const globalError = createSlice({
  name: "globalError",

  initialState: {
    errorMsg: null,
  },
  reducers: {
    showGlobalError: (state, action) => {
      state.errorMsg = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { showGlobalError } = globalError.actions;

export default globalError.reducer;
