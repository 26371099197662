import axios from "axios";

export const baseURL = process.env.REACT_APP_BASE_URL;
// export const baseURL = "http://localhost:8081/e-kyc";


const headers = {
  "Content-Type": "application/json",
};

const apiInstance = axios.create({
  baseURL,
  headers,
});

export { apiInstance };
