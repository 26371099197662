import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { MdOutlineModeEditOutline } from "react-icons/md";


const TextInput = ({
  label,
  placeholder,
  value,
  onChange,
  type = "text",
  onFocus = () => {},
  onBlur = () => {},
  stopWheel=false,
  id,
  success,
  editIcon = false,
  maxlen,
  width = "w-full",
  placeholderUpperCase = "",
  showInput = true,
  icon,
  isIcon = false,
  AsteriskStar = false,
  max,
  min,
}) => {
  const localInputRef = useRef(null);
  const [isShowInput, setIsShowInput] = useState(false);
  const editClickHandler = () => {
    setTimeout(() => {
      localInputRef?.current?.focus();
    }, 10);
    setIsShowInput(true);
  };
  const handleWheel = (event) => {
    const input = localInputRef.current;
    if (input) {
      input.blur();
      event.preventDefault();
    }
  };
  const conditionalFocusStype = success
    ? " focus:border-green-600"
    : " focus:border-primaryH";
  return (
    <div>
      <label
        htmlFor="first_name"
        className="block mb-2 text-sm font-medium text-gray-900"
      >
        {label}{AsteriskStar===true &&<span style={{color:"red"}}>*</span>}
      </label>
      <div className="flex items-end gap-[5px]">
        {isIcon && <div className="px-4">{icon}</div>}
        {isShowInput || showInput ? (
          <input
            ref={localInputRef}
            id={id}
            type={type}
            maxLength={maxlen}
            autoComplete="off"
            className={
              `"bg-white border border-gray-300 text-gray-700 text-sm rounded-sm ring-0 focus:ring-0 focus:border-1s w-full block ${width}  ${placeholderUpperCase} p-2.5"` +
              conditionalFocusStype
            }
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            required
            onFocus={onFocus}
            onBlur={(e) => {
              if (!showInput) setIsShowInput(false);
              onBlur(e);
            }}
            onWheel={(e)=>stopWheel? handleWheel(e): {}}
            max={max}
            min={min}
          />
        ) : (
          <div
            className="w-full align-center border-solid border-2 border-grey p-2 cursor-pointer min-h-[38px]"
            onClick={editClickHandler}
          >
            <p className="text-sm">{value}</p>
          </div>
        )}
        {editIcon && (
          <MdOutlineModeEditOutline
            size={25}
            style={{ cursor: "pointer" }}
            onClick={editClickHandler}
          />
        )}
      </div>
    </div>
  );
};

export default TextInput;

TextInput.propTypes = {
  success: PropTypes.bool,
};
