import { createSlice } from "@reduxjs/toolkit";

export const tabsSlice = createSlice({
  name: "Tabs",

  initialState: {
    activeTab: "pan",
    //marutiSuzuki active tab
    msActiveTab: "",
    kycTypeTab: "Individual",
  },
  reducers: {
    toggleTabs: (state, action) => {
      state.activeTab = action.payload;
    },
    //marutiSuzuki active tab
    msActiveTab: (state, action) => {
      state.msActiveTab = action.payload;
    },
    toggleKycTypeTab: (state, action) => {
      state.kycTypeTab = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { toggleTabs, msActiveTab, toggleKycTypeTab } = tabsSlice.actions;

export default tabsSlice.reducer;
