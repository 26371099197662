import { apiInstance } from "./networking";

export const fetchAdhaarDetails = async (payload) => {
  const { data = {} } = await apiInstance.post(
    "customer/associate-cusomer-and-fetch-address-details",
    payload
  );
  return data;
};

export const loadPANDetails = async (payload) => {
  const { data = {} } = await apiInstance.post("", payload);
  return data;
};

export const fetchShortDetailsAndRedirect = async (payload, params) => {
  const { data = {} } = await apiInstance.post(
    `partner/digilocker/shortDetail/${params.requestId}`,
    payload
  );
  return data;
};

export const requestDigilockerURLForPartnerRedirect = async (
  requestID,
  authToken
) => {
  const { data = {} } = await apiInstance
    .get(`partner/digilocker/${requestID}`, {
      headers: {
        enc_token: authToken || null,
      },
    })
    .catch((err) => {
      return { data: err?.response?.data || false };
    });

  return data;
};
export const fetchShortDetailsForPartnerDigilocker = async (
  requestID,
  authToken
) => {
  const { data = {} } = await apiInstance
    .get(`partner/digilocker/shortDetail/${requestID}`, {
      headers: {
        enc_token: authToken || null,
      },
    })
    .catch((err) => {
      return { data: err?.response?.data || false };
    });

  return data;
};
