import React from "react";
import { useSelector } from "react-redux";
// import hdfcLogo from "../../../assets/hdfc-ergo-logo.webp";
import styles from "./LogoContainer.module.css";
function LogoContainer() {
  const { channelUrl } = useSelector(
    (state) => state.partnerChannelNameSlice.marutiUserDetails
  );

  let url = "maruti";
  return (
    <>
      {channelUrl === "maruti" ? (
        <>
          <div className=" flex flex-col h-72 w-full px-[16px] mt-[30px]">
            <div className="flex  items-center justify-between ">
              <div>
                <img
                  className={styles["logo-image"]}
                  src="assets/hdfc-ergo-logo.webp"
                  alt="hdfc-ergo-logo"
                />
              </div>
              <div>
                <img
                  src="/assets/ms_logo.png"
                  alt="MS-logo"
                  className="w-[182px] h-[30px]"
                />
              </div>
            </div>
            <div className="md:ml-4 text-center py-[100px]">
              <p className="text-[#113569] font-bold text-lg mb-1">
                KYC has been made easier!
              </p>
              <p className="text-[#113569] text-sm font-medium mb-4 ">
                Choose your digital-friendly options.
              </p>
            </div>
          </div>
        </>
      ) : (
        <div className="flex flex-col items-center justify-center h-72 w-full ">
          <div className="flex flex-col items-center justify-center">
            <img
              className={styles["logo-image"]}
              src="assets/hdfc-ergo-logo.webp"
              alt="hdfc-ergo-logo"
            />
            <label className={styles["logo-tagline"]}>
              Let's complete your kYC
            </label>
            <div>
              <p className="font-bold text-lg mb-1">
                KYC has been made easier!
              </p>
              <p className="text-sm font-medium mb-4 ">
                Choose your digital-friendly options.
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default LogoContainer;

//   <div className={styles["logo-container"]}>
