import React from "react";
import { useSelector } from "react-redux";

const MsRightSideImg = () => {
  const { channelUrl } = useSelector(
    (state) => state.partnerChannelNameSlice.marutiUserDetails
  );

  return (
    <div className="h-[100%] md:flex-col">
      <img
        src={
          channelUrl === "maruti"
            ? "/assets/LoginMobile.png"
            : "/assets/Login_Mobile.png"
        }
        alt="kyc_img"
        className="h-auto max-w-full md:h-[100%]"
      />
    </div>
  );
};

export default MsRightSideImg;
