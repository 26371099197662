import React from "react";
import { BiErrorCircle } from "react-icons/bi";
import BannerImage from "../../login-components/banner-image/BannerImage";
import LogoContainer from "../../login-components/logo-container/LogoContainer";

const pageNotFoundError = () => {
  return (
    <div className="h-full">
      <BannerImage>
        <LogoContainer />
      </BannerImage>

      <div className="flex flex-col items-center justify-center gap-5 mt-10">
        <div className=" text-red-500">
          <BiErrorCircle size={40} />
        </div>
        <div className="font-bold text-lg">
          <h1>
            Page not found <span className="text-red-500">404</span>
          </h1>
        </div>
      </div>
    </div>
  );
};

export default pageNotFoundError;
