import React from "react";
import PanCardFetchDetailsFrame from "./Frames/PanCardFetchDetailsFrame";

const PanDetailsScreen = () => {
  return (
    <div className="h-full">
      <PanCardFetchDetailsFrame />
    </div>
  );
};

export default PanDetailsScreen;
