import { fetchAdhaarDetails } from "../../services/adhaar-digilocker-service";

export async function fetchCustomerAddressFromAdhaarDigilockerResponse(
  mobileNo,
  requestID,
  panNumber,
  channelUrl
) {
  const data = JSON.stringify({
    mobileNo: mobileNo,
    requestID: requestID,
    panNumber: panNumber,
    channel: channelUrl,
  });

  let response = await fetchAdhaarDetails(data);

  if (response.success) {
    return response.data;
  }

  return false;
}
