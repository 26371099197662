import React from "react";
import { gaFunction } from "../../../Router";
import CommonButton from "../../global-components/CommonButton/CommonButton";
// import { useNavigate } from "react-router-dom";

function BottomKYCCard({ onClick = () => {},showAddNewKyc,label }) {
  const kycHandler = (kycType) => {
    gaFunction(`Add ${kycType} KYC`, `Add new ${kycType} Clicked`);
    onClick(kycType);
  };
  return (
    <>
      <div className="fixed bottom-0 p-4 w-full bg-white rounded-sm border shadow-[2px_-4px_16px_-10px_rgba(0,0,0,0.75)] z-10 space-y-3 md:space-y-0 md:inline-flex md:p-0 md:absolute md:bottom-[20px] md:flex-row md:w-[60%] md:mt-9 md:space-x-2 md:h-max md:shadow-none md:border-0 md:ml-[2%]">
      {showAddNewKyc ? (
        <CommonButton
          label={`Add ${label} KYC`}
          center
          filled={label==='Individual'|| false}
          onClickHandler={() => kycHandler(label)}
          id={"addNewKycsbtn-corporate"}
        />
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default BottomKYCCard;
