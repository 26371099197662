import React, { useCallback, useRef } from "react";

// import Webcam from "react-webcam";
// import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
// import CameraIcon from "@mui/icons-material/Camera";

import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addUserImage,
  addUserImageName,
  changeSelfieUrl,
} from "../Redux/CameraSlice";

// import { Grid } from "@mui/material";
import { Box } from "@mui/material";
import { IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import BannerImage from "../../Components/login-components/banner-image/BannerImage";
import LogoContainer from "../../Components/login-components/logo-container/LogoContainer";
import BottomCard from "../../Components/login-components/bottom-card/BottomCard";
import { FiCamera } from "react-icons/fi";
import CommonButton from "../../Components/global-components/CommonButton/CommonButton";
import { useEffect } from "react";
import MsHome from "../../Components/Maruti-Suzuki-components/MsHome";
import styles from "./CameraScreen.module.css";
import { BrowserView, MobileView } from "react-device-detect";
import Webcam from "react-webcam";
import { gaFunction } from "../../Router";

// var elem =
//   document.compatMode === "CSS1Compat"
//     ? document.documentElement
//     : document.body;
// const videoConstraints = {
//   width: { min: 480 },
//   height: { min: 720 },
//   aspectRatio: 0.6666666667,
// };

const Camerascreen = () => {
  // const webcamRef = useRef(null);
  //   const [url, setUrl] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const webcamRef = useRef(null);
  const { channelUrl } = useSelector(
    (state) => state.partnerChannelNameSlice.marutiUserDetails
  );
  const frontFileName = useSelector(
    (state) => state.uploadedFile.documentImage.frontFile
  );
  const getStyles = () => {
    if (channelUrl === "maruti") {
      return "#113569";
    } else {
      return "#df4549";
    }
  };
  const useStyles = makeStyles((theme) => ({
    root: {
      height: "calc(100vh - 25rem)",
      textAlign: "center",
      marginTop: "-55px",
    },
    imgBox: {
      maxWidth: "80%",
      maxHeight: "80%",
      margin: "10px",
    },
    img: {
      height: "inherit",
      maxWidth: "inherit",
    },
    input: {
      display: "none",
    },
  }));
  const classes = useStyles();
  const { state } = useLocation();
  const { isRouteAllowed } = state || {};

  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user",
  };
  // const backHandler = () => {
  //   navigate("/uploadDocument");
  // };
  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    fetch(imageSrc)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], new Date().valueOf(), {
          type: "image/jpeg",
        });
        const userImage = URL.createObjectURL(file);
        dispatch(addUserImage(userImage));
        dispatch(changeSelfieUrl(true));
        dispatch(addUserImageName(file.name));
        navigate("/image", { state: { isRouteAllowed: true } });
      });
  }, [webcamRef, dispatch]);

  const handleCapture = (target) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0];
        const userImage = URL.createObjectURL(file);
        dispatch(addUserImage(userImage));
        dispatch(changeSelfieUrl(true));
        dispatch(addUserImageName(file.name));
        navigate("/image", { state: { isRouteAllowed: true } });
      }
    }
  };
  const onButtonClickHandler = () => {
    gaFunction("Camera", "Selfie File Uploaded");
    document.getElementById("icon-button-file").click();
  };
  const captureGallary = () => {
    gaFunction("Camera", "Selfie Captured");
    document.getElementById("icon-button-file-gallary").click();
    dispatch(changeSelfieUrl(true));
  };
  useEffect(() => {
    if (!state) navigate("/");
  }, [navigate, state]);

  useEffect(() => {
    // disable browser button
    return () => {
      setTimeout(() => {
        window.history.forward();
      }, 0);
    };
  }, []);

  useEffect(() => {
    if (!frontFileName?.length) navigate("/kyc");
  }, [frontFileName?.length]);

  return (
    <>
      {isRouteAllowed && (
        <div className="h-full flex flex-col ">
          <MsHome>
            <BannerImage>
              {/* <CommonTopBackButton backHandler={backHandler} /> */}
              <LogoContainer />
            </BannerImage>
            <div className={`${styles["camera-screen-container mt-10px"]}`}>
              <BottomCard max>
                <MobileView>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      height: "100%",
                      marginTop: 0,
                    }}
                  >
                    <div className="md:border md:w-[60%]  mb-10  md:shadow-md md:rounded-md">
                      <h5 className="font-bold mt-2 text-center">
                        Upload Your Selfie / photograph
                      </h5>
                      <input
                        accept="image/jpeg,image/png,image/x-eps,image/jpg,image/heic"
                        className={classes.input}
                        id="icon-button-file"
                        type="file"
                        capture="environment"
                        onChange={(e) => handleCapture(e.target)}
                      />
                      <label htmlFor="icon-button-file">
                        <IconButton
                          color="secondary"
                          aria-label="upload picture"
                          component="span"
                          sx={{ marginY: 12, display: "flex" }}
                        >
                          <FiCamera fontSize={50} color={getStyles()} />
                        </IconButton>
                      </label>

                      <Box
                        sx={{
                          width: "225px",
                          marginLeft: "auto",
                          marginRight: "auto",
                          marginBottom: 0.5,
                        }}
                      >
                        <CommonButton
                          label="Capture Photo"
                          htmlFor="icon-button-file"
                          filled
                          center
                          onClickHandler={onButtonClickHandler}
                        />
                      </Box>
                      <Box
                        sx={{
                          width: "225px",
                          marginLeft: "auto",
                          marginRight: "auto",
                          marginBottom: 5,
                          marginTop: 1,
                        }}
                      >
                        <input
                          hidden
                          accept="image/jpeg,image/png,image/x-eps,image/jpg,image/heic"
                          className={classes.input}
                          id="icon-button-file-gallary"
                          type="file"
                          onChange={(e) => handleCapture(e.target)}
                        ></input>
                        <CommonButton
                          label="Upload from gallary"
                          htmlFor="icon-button-file"
                          filled
                          center
                          onClickHandler={captureGallary}
                        />
                      </Box>
                    </div>
                  </Box>
                </MobileView>
                <BrowserView>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      height: "100%",
                      marginTop: 0,
                    }}
                  >
                    <Webcam
                      audio={false}
                      height={720}
                      ref={webcamRef}
                      screenshotFormat="image/jpeg"
                      width={1280}
                      mirrored={true}
                      videoConstraints={videoConstraints}
                      style={{
                        // position: "absolute",
                        // textAlign: "center",
                        // zindex: 8,
                        // right: 0,
                        height: "40vh",
                        width: "100%",
                        // objectFit: "fill",
                      }}
                    />
                    <Box
                      sx={{
                        width: "67%",
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginBottom: 0.5,
                        marginTop: 1,
                        display: "flex",
                        gap: 5,
                      }}
                    >
                      <CommonButton
                        label="Capture Photo"
                        htmlFor="icon-button-file"
                        filled
                        center
                        onClickHandler={capture}
                      />
                      <input
                        hidden
                        accept="image/jpeg,image/png,image/x-eps,image/jpg,image/heic"
                        className={classes.input}
                        id="icon-button-file-gallary"
                        type="file"
                        onChange={(e) => handleCapture(e.target)}
                      ></input>
                      <CommonButton
                        label="Upload"
                        htmlFor="icon-button-file"
                        filled
                        center
                        onClickHandler={captureGallary}
                      />
                    </Box>
                    {/* <Box
                      sx={{
                        width: "225px",
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginBottom: 5,
                        marginTop: 1,
                      }}
                    >
                      <input
                        hidden
                        accept="image/jpeg,image/png,image/x-eps,image/jpg,image/heic"
                        className={classes.input}
                        id="icon-button-file-gallary"
                        type="file"
                        onChange={(e) => handleCapture(e.target)}
                      ></input>
                      <CommonButton
                        label="Upload from gallary"
                        htmlFor="icon-button-file"
                        filled
                        center
                        onClickHandler={captureGallary}
                      />
                    </Box> */}
                  </Box>
                </BrowserView>
              </BottomCard>
            </div>
          </MsHome>
        </div>
      )}
    </>
  );
};

export default Camerascreen;
