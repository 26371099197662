import React, { useEffect, useState } from "react";
import CommonButton from "../../Components/global-components/CommonButton/CommonButton";
import TextInput from "../../Components/global-components/CommonTextInput/TextInput";
import BannerImage from "../../Components/login-components/banner-image/BannerImage";
import BottomCard from "../../Components/login-components/bottom-card/BottomCard";
import LogoContainer from "../../Components/login-components/logo-container/LogoContainer";
import styles from "./LoginScreen.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateUserData, userData } from "../Redux/userData";
import { getOtp, getOtpV2 } from "../../services/OTP-service";
import Loadingoverlay from "../../Components/global-components/LoadingOverlay/Loadingoverlay";
import { useOnKeyPress } from "../../hooks/useOnKeyPress";
import MsHome from "../../Components/Maruti-Suzuki-components/MsHome";
import { gaFunction } from "../../Router";
import CommonTemplate from "../../Components/global-components/CommonModal";
import { updateMarutiUserDetails } from "../Redux/partnerChannelNameSlice";
import { showGlobalError } from "../Redux/globalErrorSlice";
import { encrypt,decrypt } from "../../utils/encryptionV2";

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();

  // function useQuery() {
  //   const { search } = useLocation();
  //   return React.useMemo(() => new URLSearchParams(search), [search]);
  // }
  // let query = useQuery();

  const { name, phoneNumber } = useSelector(
    (state) => state.userDetails.userData
  );
  const { channelUrl } = useSelector(
    (state) => state.partnerChannelNameSlice.marutiUserDetails
  );

  useEffect(() => {
    getWindowDimensions();
  }, []);

  const [userName, setUserName] = useState(name);
  const [userNumber, setUserNumber] = useState(phoneNumber);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [bottomButtonVisibility, setBottomButtonVisibility] = useState(true);
  // const [windowDimensions, setWindowDimensions] = useState({
  //   width: 100,
  //   height: 100,
  // });

  useEffect(() => {
    getWindowDimensions();
  }, []);

  const mobileNumberRegex = new RegExp(
    "^(?:(?:\\+|0{0,2})91(\\s*[\\ -]\\s*)?|[0]?)?[7896]\\d{9}|(\\d[ -]?){10}\\d$"
  );

  const validateAndSetUserNumber = (value) => {
    if (value.length <= 10) {
      setUserNumber(value);
    }

    // eslint-disable-next-line no-invalid-regexp

    setErrorMessage(null);

    if (!mobileNumberRegex.test(value)) {
      setErrorMessage("Please enter valid mobile no.");
    }
  };

  const getOtpHandler = () => {
    gaFunction("login", "login button clicked");
    const data = JSON.stringify({
      mobile: userNumber,
      length: 6,
      recurring: true,
      use_type: "login",
      template: 2,
    });
    let encryptedData=encrypt(data)
    if (userName.length !== 0 && userNumber.length !== 0) {
      dispatch(updateUserData({ name: userName, phoneNumber: userNumber }));
      getOtpV2(encryptedData)
        .then(function (data) {
     
          let decryptedData =decrypt(data.enc_response)
     
          if (mobileNumberRegex.test(userNumber)) {
            if (decryptedData.success === true) {
              setIsLoading((prev) => !prev);
              // alert(decryptedData.message);
              navigate("/otp");
            } else {
              dispatch(showGlobalError(decryptedData.message));
            }
          }
        })
        .catch(function (error) {
           
          // dispatch(
          //   showGlobalError("Error while sending otp. Please try again later")
          // );
        });
    } else {
      setErrorMessage("Please fill all the inputs");
      if (userName.length === 0 && userNumber.length > 0) {
        setErrorMessage("Please fill your name");
      }
    }
    // localStorage

    const query = new URLSearchParams(window.location.search);

    localStorage.setItem("txnId", query.get("txnId"));
    localStorage.setItem(
      "redirectUrl",
      decodeURIComponent(query.get("redirectUrl"))
    );
    localStorage.setItem("mobileNumber", userNumber);
    localStorage.setItem("userName", userName);
    localStorage.setItem("channel", query.get("channel"));
    localStorage.setItem(
      "callbackUrl",
      decodeURIComponent(query.get("callbackUrl"))
    );
    dispatch(
      updateMarutiUserDetails({
        channelUrl: query.get("channel"),
        mi_u: query.get("txnId"),
        subChannel:query.get("subChannel")
      })
    );
  };

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    // setWindowDimensions({ width, height });
  }

  useOnKeyPress("Enter", getOtpHandler);

  // const onInputFieldFocusHandler = () => {
  //   setBottomButtonVisibility(false);
  // };

  // const onInputFieldBlurHandler = () => {
  //   setBottomButtonVisibility(true);
  // };

  useEffect(() => {
    dispatch({ type: "USER_LOGOUT" });
    localStorage.clear();
  }, [dispatch]);
  return (
    <>
      {isLoading ? (
        <Loadingoverlay />
      ) : (
        <div className="h-full flex flex-col">
          <MsHome>
            <BannerImage>
              <LogoContainer />
            </BannerImage>
            <div className="bg-secondaryH h-full  ">
              <BottomCard stepper={false}>
                <div className="md:w-[100%] mt-0  md:mt-0 ">
                  <label>
                    <b>Login</b>
                  </label>
                  <div className={styles["form-container"]}>
                    <div className="md:w-[60%]">
                      <div className="mt-2"></div>
                      <TextInput
                        id={"loginNameField"}
                        label={"Full Name"}
                        value={userName}
                        // placeholder={"Enter Full Name as per PAN Card"}
                        placeholder={"Enter Full Name"}
                        onChange={(e) => setUserName(e.target.value)}
                      />
                      <div className="mt-4"></div>
                      <TextInput
                        id={"loginNumberField"}
                        value={userNumber}
                        type="tel"
                        label={"Mobile number"}
                        placeholder={"Eg.99999 00000"}
                        onChange={(e) =>
                          validateAndSetUserNumber(e.target.value)
                        }
                      />

                      {errorMessage && (
                        <p className="text-red-500">{errorMessage}</p>
                      )}
                    </div>
                  </div>
                  <div className="h-1/3 md:h-0"></div>

                  <footer className="w-full mt-5 mb-4 md:mb-0">
                    <div className="mb-5 ">
                      <p className="text-sm text-slate-500">
                        Note : Please share the name and mobile number of the
                        proposer for the policy.
                      </p>
                      {/* <span className="text-sm">
                        For queries contact : &nbsp;
                      </span>
                      <a
                        href="mailto: ekyc.support@hdfcergo.com"
                        className="text-blue-500 text-sm"
                      >
                        ekyc.support@hdfcergo.com
                      </a> */}
                    </div>
                    <div className="md:w-[235px]">
                      <CommonButton
                        label="Get OTP"
                        filled
                        center
                        id={"getOtp"}
                        onClickHandler={getOtpHandler}
                      />
                    </div>
                  </footer>
                </div>
              </BottomCard>
            </div>
          </MsHome>
        </div>
      )}
    </>
  );
};

export default LoginPage;
