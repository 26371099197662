import { createSlice } from "@reduxjs/toolkit";

export const panCardSlice = createSlice({
  name: "panDetails",

  initialState: {
    panUserdata: {},
    userPanNumber: "",
    userPanDOB: "",
  },
  reducers: {
    panUserdata: (state, action) => {
      state.panUserdata = action.payload;
    },
    // aadhaarUserdata: (state, action) => {
    //   state.aadhaarUserdata = action.payload;
    // },
    userPanNumber: (state, action) => {
      state.userPanNumber = action.payload;
    },
    userPanDOB: (state, action) => {
      state.userPanDOB = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { panUserdata, userPanNumber , userPanDOB } = panCardSlice.actions;

export default panCardSlice.reducer;
