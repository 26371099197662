import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import LoginPage from "../../../Screens/LoginScreen/LoginScreen";
import { validateAPIKeyToken } from "../../../services/apikey-Token-service";
import Loadingoverlay from "../LoadingOverlay/Loadingoverlay";

const checkValidToken = async (token) => {
  let validateTokenResponse = await validateAPIKeyToken().catch((e) => {
    return false;
  });
  //   debugger;
  console.log("returning", validateTokenResponse.success);
  return validateTokenResponse.success || false;
};

const ProtectedRoute = () => {
  const [loadingTokenInfo, setLoadingTokenInfo] = useState(true);
  const [tokenValidity, setTokenValidity] = useState(false);

  useEffect(() => {
    checkValidToken().then((data) => {
      setTokenValidity(data);
      setLoadingTokenInfo(false);
    });
  }, []);

  return (
    <>
      {loadingTokenInfo ? (
        <Loadingoverlay />
      ) : tokenValidity ? (
        <Outlet />
      ) : (
        <LoginPage />
      )}
    </>
  );
};

export default ProtectedRoute;
