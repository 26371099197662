import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import BannerImage from "../../Components/login-components/banner-image/BannerImage";
import BottomCard from "../../Components/login-components/bottom-card/BottomCard";
import LogoContainer from "../../Components/login-components/logo-container/LogoContainer";
import MsCommonTab from "../../Components/Maruti-Suzuki-components/MsCommonTab";

import MsHome from "../../Components/Maruti-Suzuki-components/MsHome";
import { gaFunction } from "../../Router";
import { marutiUrl } from "../../services/maruti-service";
import AadhaarCardFrame from "../KYCHomeScreen/Frames/AadhaarCardFrame/AadhaarCardFrame";
import OtherDocsFrame from "../KYCHomeScreen/Frames/OtherDocsFrame";
import PanCardFrame from "../KYCHomeScreen/Frames/PanCardFrame";
import { storeToken } from "../Redux/authSlice";
import { documentDetail } from "../Redux/otherDocsSlice";
import { panUserdata } from "../Redux/panCardSlice";

import { msActiveTab } from "../Redux/slice";
import { toggleButton } from "../Redux/switchToggleSlice";
import { customerData, updateUserData } from "../Redux/userData";

const tabs = {
  tab1: {
    img: "/assets/pan.png",
    head: "Search Existing CKYC",
    para: "Share documents number like PAN, Voter ID number or Driving License Number etc",
  },
  tab2: {
    img: "/assets/aadhaar.png",
    head: "Instant Aadhar Based KYC",
    para: "Mobile number linked with Aadhar required",
  },
  tab3: {
    img: "/assets/ovd.png",
    head: "Manual KYC",
    para: "Upload documents like Voter ID or Driving License or Passport etc",
  },
};

const MsTabScreen = () => {
  const [isPanCard, setIsPanCard] = useState(false);
  const [isAadharCard, setIsAadharCard] = useState(false);
  const [isOvd, setIsOvd] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const navigate = useNavigate();
  const tab = useSelector((state) => state.Tabs.msActiveTab);
  // const activeTabs = useSelector((state) => state.Tabs.activeTab);
  const { typeOfUI,subChannel,mi_u, channelUrl } = useSelector(
    (state) => state.partnerChannelNameSlice.marutiUserDetails
  );

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  const cKycTabHandler = () => {
    let data = JSON.stringify({
      mobile: "1111111111",
    });
    marutiUrl(data)
      .then(function (data) {
        if (data.success === true) {
          localStorage.setItem("token", data.data.token);
          dispatch(storeToken(data.data.token));
          // navigate("/kyc");
          setIsPanCard((prev) => !prev);
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    dispatch(msActiveTab("ckyc"));
  };
  const aadhaarTabHandler = () => {
    let data = JSON.stringify({
      mobile: "1111111111",
    });
    marutiUrl(data)
      .then(function (data) {
        if (data.success === true) {
          localStorage.setItem("token", data.data.token);
          dispatch(storeToken(data.data.token));
          // navigate("/kyc");
          setIsAadharCard((prev) => !prev);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    dispatch(msActiveTab("aadhaar"));
  };

  const ovdTabHandler = () => {
    let data = JSON.stringify({
      mobile: "1111111111",
    });
    marutiUrl(data)
      .then(function (data) {
        if (data.success === true) {
          localStorage.setItem("token", data.data.token);
          dispatch(storeToken(data.data.token));
          // navigate("/otherDocs");
          setIsOvd((prev) => !prev);
        }
      })
      .catch(function (error) {});
    dispatch(msActiveTab("ovd"));
  };

  const resetDB = useCallback(() => {
    dispatch(panUserdata({}));
    dispatch(documentDetail({ dob: "", fatherName: "" }));
    dispatch(customerData({ fatherName: "" }));
    dispatch(updateUserData({ phoneNumber: "" }));
  }, [dispatch]);

  useEffect(() => {
    // disable browser button
    return () => {
      setTimeout(() => {
        window.history.forward();
      }, 0);
    };
  }, []);
  useEffect(() => {
    localStorage.setItem("txnId", query.get("txnId"));
    localStorage.setItem("subChannel", query.get("subChannel"));
  }, []);

  useEffect(() => {
    if (!isPanCard && !isOvd && !isAadharCard) {
      dispatch(toggleButton(true));
      dispatch(msActiveTab(""));
    }
  }, [isPanCard, isOvd, isAadharCard, dispatch]);

  useEffect(() => {
    gaFunction("maruti", "maruti landing page loaded");
    resetDB();
  }, [resetDB]);


  return (
    <MsHome>
      <BannerImage>
        <LogoContainer />
      </BannerImage>

      {isPanCard || isAadharCard || isOvd ? (
        <div className="bg-secondaryH h-full">
          <BottomCard>
            {isPanCard && <PanCardFrame />}
            {isAadharCard && <AadhaarCardFrame />}
            {isOvd && <OtherDocsFrame isMaruti={true} />}
          </BottomCard>
        </div>
      ) : (
        <div className=" bg-secondaryH ">
          <BottomCard stepper={false}>
            <div className="   md:mt-10 p-4 ">
              <div className="md:flex md:gap-6 md:mt-10 mt-2  w-full">
                {typeOfUI === "w" && (
                  <MsCommonTab
                    image={tabs.tab1.img}
                    heading={tabs.tab1.head}
                    description={tabs.tab1.para}
                    isActive={tab === "ckyc"}
                    onClick={cKycTabHandler}
                  />
                )}
                <MsCommonTab
                  image={tabs.tab2.img}
                  heading={tabs.tab2.head}
                  description={tabs.tab2.para}
                  isActive={tab === "aadhaar"}
                  onClick={aadhaarTabHandler}
                />
                {!(subChannel==="web" && mi_u.slice(0,3)==="KY3") &&
                <MsCommonTab
                  image={tabs.tab3.img}
                  heading={tabs.tab3.head}
                  description={tabs.tab3.para}
                  isActive={tab === "ovd"}
                  onClick={ovdTabHandler}
                />}
              </div>
            </div>
          </BottomCard>
        </div>
      )}
    </MsHome>
  );
};

export default MsTabScreen;
