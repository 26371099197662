import { createSlice } from "@reduxjs/toolkit";

export const otherDocsDropDownValue = createSlice({
  name: "dropDownValue",

  initialState: {
    selectedValue: "aadhaar",
  },
  reducers: {
    optionsValue: (state, action) => {
      state.selectedValue = action.payload;
    },
  },
});

export const { optionsValue } = otherDocsDropDownValue.actions;
export default otherDocsDropDownValue.reducer;
