import { createSlice } from "@reduxjs/toolkit";
export const voterIdSlice = createSlice({
  name: "voterId",
  initialState: {
    voterDetails: "",
  },
  reducers: {
    voterIdUserDetails: (state, action) => {
      state.voterDetails = action.payload;
    },
  },
});

export const { voterIdUserDetails } = voterIdSlice.actions;
export default voterIdSlice.reducer;
