import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import styles from "../Screens/KYCHomeScreen/Frames/UploadDocumnetDrawer.module.css";
import { makeStyles } from "@mui/styles";
import { IconButton } from "@mui/material";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { allowedFileSize } from "../Router";
import { showGlobalError } from "../Screens/Redux/globalErrorSlice";
import { coFileUpload } from "../Screens/Redux/corporateSlice";
import {
  fileUpload,
  formSixtyDocument,
  formSixtyDocumentName,
  previewIamgeURL,
} from "../Screens/Redux/otherDocsSlice";
const useStyles = makeStyles({
  root: {
    "& .MuiPaper-root": {
      borderTopLeftRadius: 30,
      borderTopRightRadius: 30,
      "@media (min-width: 500px)": {
        width: "50%",
        margin: "auto",
      },
    },
  },
});

const CorporateUploadFile = ({
  onClose = () => {},
  title = "",
  acceptFileType = "image/jpeg,image/png,image/x-eps,image/jpg,image/heic/pdf",
  isFormSixty = false,
  isNavigate = false,
}) => {

  const classes = useStyles();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [state, setState] = useState({
    bottom: true,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
    onClose(open);
  };

  const files = {};

  const uploadFrontFileHandler = (e) => {
    if (title) {
      if (e.target.files[0]?.type !== "application/pdf") {
        onClose(false);
        return dispatch(
          showGlobalError("Supported file formats are PDF only.")
        );
      }
    }
    if (e.target.files[0].size / 1000 > allowedFileSize) {
      onClose(false);
      return dispatch(
        showGlobalError(
          "File size exceeds maximum limit. Maximum allowed file size is 10 MB"
        )
      );
    }
    if (title !== "") {
      if (e.target.files[0]) {
        files[title] = {
          FileName: e.target.files[0].name,
          FileImage: URL.createObjectURL(e.target.files[0]),
          title: title,
        };
        dispatch(coFileUpload(files));
        onClose(false);
      }
    }
    if (e.target.files[0]) {
      if (isFormSixty) {
        if (e.target.files[0]?.type !== "application/pdf" && title === "") {
          onClose(false);
          return dispatch(
            showGlobalError("Supported file formats are PDF only.")
          );
        }
        dispatch(formSixtyDocument(URL.createObjectURL(e.target.files[0])));
        dispatch(formSixtyDocumentName(e.target.files[0].name));
      } else {
        if (e.target.files[0]?.type === "application/pdf" && title === "") {
          onClose(false);
          return dispatch(
            showGlobalError(
              "PDF is not allowed. Supported file formats are png, jpeg, jpg and heic."
            )
          );
        }
        if (isNavigate) navigate("/coroporateUpload");
        dispatch(fileUpload({ frontFile: e.target.files[0].name }));
        dispatch(
          previewIamgeURL({
            frontImageURL: URL.createObjectURL(e.target.files[0]),
          })
        );
      }
      onClose(false);
    }
  };

  const list = (anchor) => (
    <Box
      className={styles["drawer"]}
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
      }}
      role="presentation"
    >
      <p>Upload document from</p>
      <div className="p-2"></div>
      <div className="flex items-center gap-10">
        <div>
          <IconButton
            aria-label="upload picture"
            component="label"
            sx={{
              border: "1px solid var(--primary-color)",
            }}
          >
            <input
              hidden
              accept={acceptFileType}
              type="file"
              onChange={uploadFrontFileHandler}
            />
            <CollectionsOutlinedIcon sx={{ color: "var(--primary-color)" }} />
          </IconButton>
          <p>Gallery</p>
        </div>
      </div>
    </Box>
  );

  return (
    <div>
      {["bottom"].map((anchor, index) => (
        <React.Fragment key={Math.random() + index}>
          <Drawer
            onClose={toggleDrawer(anchor, false)}
            anchor={anchor}
            open={state[anchor]}
            classes={classes}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
};

export default CorporateUploadFile;
