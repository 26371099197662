import { apiInstance } from "./networking";

export const getPanCardDetails = async (payload, { txnid }) => {
  const { data = {} } = await apiInstance.post(
    // SINZY open API
    // `primary/v2/ckyc-details?txnid=${txnid}`,
    // SINZY encrypted API
    // `primary/ckyc-details?txnid=${txnid}`,
    // ARYA API
    `primary/arya-ind-details?txnid=${txnid}`,
    payload
  );
  return data;
};


export const getPanCardDetailsV2 = async (payload, txnid ) => {
  console.log({payload})
  const { data = {} } = await apiInstance.post(
    // SINZY open API
    // `primary/v2/ckyc-details?txnid=${txnid}`,
    // SINZY encrypted API
    // `primary/ckyc-details?txnid=${txnid}`,
    // ARYA API
    `/V2/primary/arya-ind-details?${txnid}`,
    payload
  );
  return data;
};
