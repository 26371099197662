import React from "react";
import { useSelector } from "react-redux";
import Steppers from "../../Components/kyc-components/Steppers";
import MsHome from "../../Components/Maruti-Suzuki-components/MsHome";
import MsCkycFrame from "./marutiSuzukiFrame/MsCkycFrame";

const MsCkycScreen = () => {
  const stepLable = useSelector((state) => state.stepperSlice.activeStepper);
  return (
    <MsHome>
      <div className="mt-10 w-[60%]">
        <Steppers label={"Fetch"}>
          <div>{stepLable === 1 || 0 ? <MsCkycFrame /> : "hello"}</div>
        </Steppers>
      </div>
    </MsHome>
  );
};

export default MsCkycScreen;
