

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  formSixtyData:{
      userFirstName:"",
      userMiddleName:"",
      userSurName:"",
      dateOfBirth:"",
      fatherFirstName:"",
      fatherMiddleName:"",
      fatherSurName:"",
      roomNo:"",
      floorNo:"",
      PremisesName:"",
      BlockNo:"",
      area:"",
      town:"",
      district:"",
      states:"",
      pinCode:"",
      telePhoneNumber:"",
      mobileNumber:"",
      premiumAmount:"",
      dateOfTransaction:"",
      numberOfPerson:"",
      modeOfTransaction:"",
      aadhaarNumber:"",
      appliedForPan:"",
      dateOfApplication:"",
      agriculturalIncome:"",
      otherAgriculturalIncome:"",
      placeWhereSigned:"",
      acknowledgementNumber:"",
      signatureFile:"",
      signatureName:"",
      documentCode:"01",
      authorityName:"",
      digitalForm60Id:"",
      documentIdentificationNumber:"",
      formSixtyString:"",
      selectedDoc:"aadhaar",
    }
}

export const digitalFormSixty = createSlice({
  name: "digitalFormSixty",
  initialState,
  reducers: {
    formSixtyData: (state, action) => {
        state.formSixtyData = { ...state.formSixtyData, ...action.payload };
    },
    clearFormSixtyDataState: (state) => initialState,
  },
});

// Action creators are generated for each case reducer function
export const { formSixtyData ,clearFormSixtyDataState } = digitalFormSixty.actions;

export default digitalFormSixty.reducer;