import { apiInstance } from "./networking";

export const validateAPIKeyToken = async (payload) => {
  const { data = {} } = await apiInstance.post(
    `primary/verifyAPIKey`,
    payload,
    {
      headers: {
        Authorization: localStorage.getItem("token") || null,
      },
    }
  );
  return data;
};
