import React from "react";

const MsCommonTab = ({ image, heading, description, onClick, isActive }) => {
  const getStyle = () => {
    if (isActive) {
      return "bg-[#E9F1FB]";
    }
    return "bg-white";
  };

  return (
    <div
      className={`h-auto  w-full shadow-md rounded-md flex items-center ${getStyle()} cursor-pointer w-full mb-[16px] md:mb-0`}
      onClick={onClick}
    >
      <div className="p-4 ">
        <div className="flex justify-center mb-2">
          <img src={image} alt="img" />
        </div>
        <span className=" flex justify-center text-[#113569] font-bold mb-2 text-center">
          {heading}
        </span>
        <p className="text-center">{description}</p>
      </div>
    </div>
  );
};

export default MsCommonTab;
