import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleTabs } from "../../Screens/Redux/slice";
import PropTypes from "prop-types";
import { toggleButton } from "../../Screens/Redux/switchToggleSlice";
import { Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
function Tab({ isDisabled = false }) {
  // const [istoggle,setIsToggle] = useState()
  const tabs = useSelector((state) => state.Tabs.activeTab);
  // const istoggle = useSelector((state) => state.switchButton.activeSwitch);
  const dispatch = useDispatch();
  const { channelUrl } = useSelector(
    (state) => state.partnerChannelNameSlice.marutiUserDetails
  );

  return (
    <>
      {channelUrl === "maruti" ? (
        ""
      ) : (
        <div className="md:w-full">
          <ul className=" flex flex-row   w-full   text-sm font-medium text-center text-gray-500 dark:text-gray-400 gap-3 xs:text-red-500 cursor-pointer">
            <li className="">
              <TabPill
                label={"PAN"}
                onClick={() => {
                  dispatch(toggleTabs("pan"));
                  dispatch(toggleButton(true));
                }}
                isActive={tabs === "pan"}
                disable={isDisabled}
                id={"panckyc"}
              />
              {/* <Tooltip id="button-report1" title="Report">
                <InfoIcon sx={{ color: "black", fontSize: "22px" }} />
              </Tooltip> */}
            </li>

            <li className="">
              <TabPill
                label={"Aadhaar using UIDAI"}
                onClick={() => {
                  dispatch(toggleTabs("aadhaar"));
                  dispatch(toggleButton(true));
                }}
                isActive={tabs === "aadhaar"}
                disable={isDisabled}
                id={"aadhaarkyc"}
              />
              {/* <Tooltip id="button-report2" title="Report">
                <InfoIcon sx={{ color: "black", fontSize: "22px" }} />
              </Tooltip> */}
            </li>

            {/* <li className="w-1/3">
          <TabPill
            label={"Other OVDs"}
            onClick={() => dispatch(toggleTabs("otherDocs"))}
            isActive={tabs === "otherDocs"}
          />
        </li> */}
          </ul>
        </div>
      )}
    </>
  );
}

export default Tab;
Tab.propTypes = {
  isDisabled: PropTypes.bool,
};

export const TabPill = ({
  label,
  onClick = () => {},
  isActive,
  disable = false,
  id,
  style = { padding: 3 },
  isInfoIcon = true,
}) => {
  const getStyle = () => {
    if (isActive) {
      return `inline-block  flex gap-[4px] h-full w-full p-${style?.padding} px-${style?.paddingLeftRight}  bg-white-600 border bg-[#FCE9E9] border-red-500 text-black rounded-lg  shadow-sm cursor-pointer`;
    }
    return `inline-block  flex gap-[4px] h-full w-full p-${style?.padding} px-${
      style?.paddingLeftRight
    } bg-white-600 text-gray-500 border rounded-lg shadow-md cursor-pointer ${
      disable ? "pointer-events-none" : ""
    }`;
  };
  const panToolTipString = "Data id fetched from CKYC using your Pan and DOB.";
  const aadhaarToolTipString =
    "Data id fetched from UIDAI using your Aadhaar number.";

  return (
    <div className={getStyle()} aria-current="page" onClick={onClick} id={id}>
      {label}
      {isInfoIcon && (
        <div className="flex">
          <Tooltip
            id="button-report1"
            title={
              label === "Aadhaar" ? aadhaarToolTipString : panToolTipString
            }
          >
            <InfoIcon sx={{ color: "black", fontSize: "17px" }} />
          </Tooltip>
        </div>
      )}
    </div>
  );
};
