import PropTypes from "prop-types";

const ToggleButton = ({ onChange, checked }) => {
  return (
    <>
      <label
        htmlFor="green-toggle"
        className="inline-flex relative items-center mr-5 cursor-pointer"
      >
        <input
          type="checkbox"
          value={""}
          id="green-toggle"
          className="sr-only peer"
          onChange={onChange}
          checked={checked}
        />
        <div className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-white-700 peer-focus:ring-4   peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600 focus:outline-none ring ring-0"></div>
      </label>
    </>
  );
};

export default ToggleButton;

ToggleButton.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool,
};
