import React, { useState } from "react";

import ImageUploading from "react-images-uploading";

import Cropper from "react-easy-crop";

import { useDispatch, useSelector } from "react-redux";

import { formSixtyData } from "../../../Redux/digitalFormSixtySlice";
import CloseIcon from '@mui/icons-material/Close';

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import { cropImage } from "./CropUtils";

const ImageUploadingButton = ({ value, onChange, ...props }) => {
  return (
    <ImageUploading value={value} onChange={onChange}>
      {({ onImageUpload, onImageUpdate }) => (
        <Button
          color="primary"
          onClick={value ? onImageUpload : () => onImageUpdate(0)}
          {...props}
        >
          Upload
        </Button>
      )}
    </ImageUploading>
  );
};

const ImageCropper = ({
  open,
  image,
  onComplete,
  onClose,
  containerStyle,
  ...props
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogActions>
      <CloseIcon onClick={()=>{
       onClose();
      }}></CloseIcon>
      </DialogActions>
      <DialogTitle>Crop Image</DialogTitle>
      <DialogContent>
        <div style={containerStyle}>
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={4/2}
            onCropChange={setCrop}
            onCropComplete={(_, croppedAreaPixels) => {
              setCroppedAreaPixels(croppedAreaPixels);
            }}
            onZoomChange={setZoom}
            {...props}
          />
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          onClick={() =>
            onComplete(cropImage(image, croppedAreaPixels, console.log))
          }
        >
          Finish
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default function ImageCrop() {
  const [image, setImage] = useState([]);
  const [croppedImage, setCroppedImage] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const dispatch = useDispatch();

  const onCloseHandler =()=>{
    setDialogOpen(false)
  }

  return (
    <div className="App">
      <ImageUploadingButton
        value={image}
        onChange={(e) => {
          setDialogOpen(true);
          setImage(e);
        }}
      />
      <ImageCropper
        open={dialogOpen}
        image={image.length > 0 && image[0].dataURL}
        onClose={onCloseHandler}
        onComplete={(imagePromisse) => {
          const fileName=image[0]?.file?.name;
          imagePromisse.then((image) => {
            setCroppedImage(image);
            dispatch(
              formSixtyData({
                signatureFile:image,
                signatureName:fileName,
              })
            );
            setDialogOpen(false);
          });
        }}
        containerStyle={{
          position: "relative",
          width: "100%",
          height: 300,
          background: "#333",
        }}
      />
      {croppedImage && <img src={croppedImage} alt="blab" />}
    </div>
  );
}
