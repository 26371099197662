import React from "react";
import ToggleButton from "../../../Components/kyc-components/ToggleButton";
import CommonDropDown from "../../../Components/global-components/CommonDropDown/CommonDropDown";
import { useState } from "react";
import TextInput from "../../../Components/global-components/CommonTextInput/TextInput";
import CommonButton from "../../../Components/global-components/CommonButton/CommonButton";

const options = {
  option: ["  CKYCNumber"],
};
const MsCkycFrame = () => {
  const [selectedValue, setSelectedValue] = useState(options.option[0]);
  const [dob, setDob] = useState("");

  return (
    <div className="h-[300px]  ">
      <div className="flex flex-row justify-between items-center mt-6">
        <p>Do you have a valid PAN Number?</p>
        <ToggleButton />
      </div>
      <div>
        <CommonDropDown
          label={"Select Document Type"}
          selected={selectedValue}
          setSelected={setSelectedValue}
          options={options.option}
        />
        <TextInput
          label={"Enter Document Number"}
          placeholder={"Enter CKYC Number"}
        />
        <div className="mt-5">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-700">
            Date of birth.
          </label>
        </div>
        <input
          id="dob"
          className="border color-black text-black border-black-300 w-full p-[8px] uppercase"
          type="date"
          placeholder="MM/DD/YYYY"
          value={dob}
          onChange={(e) => setDob(e.target.value)}
        />
      </div>
    </div>
  );
};

export default MsCkycFrame;
