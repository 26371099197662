import crypto from "crypto"

 let algorithm = 'aes-256-cbc';

 
 let secret_key = process.env.REACT_APP_ENCRYPTION_KEY;

 let rounds = 9921;

 let keySize = 32;

 let salt = crypto.createHash("sha1").update(secret_key).digest("hex");





function encrypt(data) {

    try {

      const iv = crypto.randomBytes(16);

      const key = crypto.pbkdf2Sync(secret_key, salt, rounds, keySize, "sha512");

  

      const cipher = crypto.createCipheriv(algorithm, key, iv);

  

      let encryptedData = cipher.update(JSON.stringify(data), "utf-8", "base64");

      encryptedData += cipher.final("base64");

  

      return iv.toString("base64") + ":" + encryptedData;

    } catch (err) {

      console.error(err);

      return false;

    }

  }

  

function decrypt(encData) {

    try {
      console.log({encData})
      const textParts = encData.split(":");

      const iv = Buffer.from(textParts.shift(), "base64");

      const encryptedData = Buffer.from(textParts.join(":"), "base64");

      const key = crypto.pbkdf2Sync(secret_key, salt, rounds, keySize, "sha512");

  

      const decipher = crypto.createDecipheriv(algorithm, key, iv);

  

      let decryptedData = decipher.update(encryptedData, "base64", "utf-8");

      decryptedData += decipher.final("utf-8");

      return JSON.parse(decryptedData);

    } catch (err) {

      console.error(err);

      return false;

    }

  }



  export { encrypt, decrypt };