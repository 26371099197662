import React, { useState } from "react";
import CommonButton from "../../../Components/global-components/CommonButton/CommonButton";
import CommonDropDown from "../../../Components/global-components/CommonDropDown/CommonDropDown";
import BannerImage from "../../../Components/login-components/banner-image/BannerImage";
import BottomCard from "../../../Components/login-components/bottom-card/BottomCard";
import LogoContainer from "../../../Components/login-components/logo-container/LogoContainer";

import { useLocation, useNavigate } from "react-router-dom";
import styles from "./Annual.module.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import Tab from "../../../Components/kyc-components/Tab";
import { useEffect } from "react";
import MsHome from "../../../Components/Maruti-Suzuki-components/MsHome";
import { activeStepper } from "../../Redux/stepperSlice";
import { gaFunction, scrollIntoViewFunc } from "../../../Router";
import { TextField } from "@mui/material";
import {clearFormSixtyDataState} from "../../Redux/digitalFormSixtySlice";

const options = {
  option1: [
    "Select",
    "0-2.5 lacs",
    "2.5-5 lacs",
    "5-15 lacs",
    "15-20 lacs",
    "20-30 lacs",
    "30+ lacs",
  ],
  option2: [
    "Select",
    "Salaried",
    "Professional",
    "Self Employed",
    "Student",
    "Home maker",
    "Other",
  ],
  option3: [
    "Select",
    "Government",
    "Private Limited",
    "Public Limited",
    "Trust",
    "Other",
  ],
  option4: ["Select", "Yes", "No", "Related to one"],
};

const AnnualIncome = () => {
  const [annualIncome, setAnnaulIncome] = useState("");
  const [error, setError] = useState("");
  const [occupation, setOccupation] = useState("");
  const [organization, setOrganization] = useState("");
  const [political, setPolitical] = useState("");
  const [occupationOther, setOccupationOther] = useState("");
  const [organizationOther, setOrganizationOther] = useState("");
  let { phoneNumber } = useSelector((state) => state.userDetails.userData);
  let {
    gender,
    maritalStatus,
    mothersName,
    fatherName,
    correspondenceAddress,
    correspondenceState,
    correspondenceCity,
    correspondencePincode,
    userEditedName,
    userEditedAddress,
    permanentAddress,
    permanentCity,
    permanentDistrict,
    permanentCountry,
    permanentState,
    permanentPincode,
    name,
    manualKYC,
    dob,
  } = useSelector((state) => state.userDetails.customerData);
  console.log("-----INCOME------",
  {
    gender,
    maritalStatus,
    mothersName,
    fatherName,
    correspondenceAddress,
    correspondenceState,
    correspondenceCity,
    correspondencePincode,
    userEditedName,
    userEditedAddress,
    permanentAddress,
    permanentCity,
    permanentDistrict,
    permanentCountry,
    permanentState,
    permanentPincode,
    name,
    manualKYC,
    dob,
  })
  const userPanNumber = useSelector((state) => state.panDetails.userPanNumber);
  const { mi_u, channelUrl,subChannel } = useSelector(
    (state) => state.partnerChannelNameSlice.marutiUserDetails
  );
  const kycRefId = useSelector((state) => state.uploadedFile.kycRefId);
  const kycId = useSelector((state) => state.kycDetails.kycRefIdAddNewKyc);
  const { dob:dobOVD, fatherName: fatherNameOVD } = useSelector(
    (state) => state.uploadedFile.previewDocumentDetail
  );
  const digitalFormSixtyID= useSelector((state) => state.digitalFormSixty.formSixtyData.digitalForm60Id);
  const { state } = useLocation();
  const { isRouteAllowed, type, typeOfDoc } = state || {};

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // remove on production
  if (Object.keys(phoneNumber).length === 0)
    phoneNumber = localStorage.getItem("mobileNumber");

  // const backHandler = () => {
  //   navigate("/panDetails");
  // };

  const onClickHandler = () => {
    gaFunction("Income", "Income Proceed Clicked");
    if (
      annualIncome !== "" &&
      occupation !== "" &&
      organization !== "" &&
      political !== ""
    ) {
      if (
        (occupation === "Other" && !occupationOther) ||
        (organization === "Other" && !organizationOther)
      ) {
        setError("Please fill up the given form");
        setTimeout(() => {
          scrollIntoViewFunc("incomeError");
        }, 1);
        return;
      }
      let data = JSON.stringify({
        // mobile: phoneNumber,
        pan: userPanNumber,
        annualIncome: annualIncome,
        occupation:
          occupation === "Other"
            ? `${occupation} - ${occupationOther}`
            : occupation,
        organizationType:
          organization === "Other"
            ? `${organization} - ${organizationOther}`
            : organization,
        isPoliticallyExposed:
          political === "Yes" || political === "Related to one" ? true : false,
        gender: gender,
        maritalStatus: maritalStatus,
        mothersName: mothersName,
        correspondenceAddress: correspondenceAddress,
        correspondenceState: correspondenceState,
        correspondenceCity: correspondenceCity,
        correspondencePincode: correspondencePincode,
        type,
        userEditedName,
        userEditedAddress,
        permanentCity,
        permanentDistrict,
        permanentCountry,
        permanentState,
        permanentPincode,
        permanentAddress,
        name,
        // mi_identifier: mi_u,
        channel: channelUrl,
        subChannel,
        txnId: mi_u,
        kycId,
        dob:dob || dobOVD || "",
        fatherName: fatherName || fatherNameOVD || "",
        manualKYC,
        digitalFormSixtyID: digitalFormSixtyID ||"",
      });
      let data1 = {
        // mobile: phoneNumber,
        pan: userPanNumber,
        annualIncome: annualIncome,
        occupation:
          occupation === "Other"
            ? `${occupation} - ${occupationOther}`
            : occupation,
        organizationType:
          organization === "Other"
            ? `${organization} - ${organizationOther}`
            : organization,
        isPoliticallyExposed:
          political === "Yes" || political === "Related to one" ? true : false,
        gender: gender,
        maritalStatus: maritalStatus,
        mothersName: mothersName,
        correspondenceAddress: correspondenceAddress,
        correspondenceState: correspondenceState,
        correspondenceCity: correspondenceCity,
        correspondencePincode: correspondencePincode,
        type,
        userEditedName,
        userEditedAddress,
        permanentCity,
        permanentDistrict,
        permanentCountry,
        permanentState,
        permanentPincode,
        permanentAddress,
        name,
        // mi_identifier: mi_u,
        channel: channelUrl,
        txnId: mi_u,
        kycId,
        dob:dob || dobOVD || "",
        fatherName: fatherName || fatherNameOVD || "",
        manualKYC,
        digitalFormSixtyID: digitalFormSixtyID ||"",
      };
      console.log({"income" : data1})
      // https://ctrln-uat.hdfcergo.com
      let config = {
        method: "patch",
        url: `${process.env.REACT_APP_BASE_URL}/customer/customer-kyc`,
        headers: {
          "Content-Type": "application/json",
          txnId: localStorage.getItem("txnId") === "null" ?? null,
          // token: localStorage.getItem("token"),
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          if (response.data.success === true) {
            dispatch(clearFormSixtyDataState());
            dispatch(activeStepper(3));
            navigate("/Success", {
              state: {
                kyc_ref_id: response?.data?.data?.kyc_id,
                successUrl: response?.data?.data?.success_url,
                typeOfKyc: typeOfDoc,
              },
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      setError("Please fill up the given form");
      setTimeout(() => {
        scrollIntoViewFunc("incomeError");
      }, 1);
    } 
  };

  useEffect(() => {
    if (!state) navigate("/login");
  }, [navigate, state]);

  useEffect(() => {
    // disable browser button
    return () => {
      setTimeout(() => {
        window.history.forward();
      }, 0);
    };
  }, []);
  return (
    <>
      {isRouteAllowed && (
        <div className={styles.AnnualIncome}>
          <MsHome>
            <BannerImage>
              {/* <MdKeyboardBackspace
              size={30}
              style={{ margin: 20 }}
              onClick={backHandler}
            /> */}
              <LogoContainer />
            </BannerImage>
            <BottomCard max>
              <div className="md:h-full">
                <div className="hidden md:block md:h-[22%]">
                  <div className="p-[10px] md:p-0"></div>
                  <label className="md:text-xl">
                    <b>KYC Document</b>
                  </label>
                  <p className="hidden md:block md:text-sm">
                    let's fetch your kyc details using
                  </p>
                  <div className="p-[10px] md:p-[6px]"></div>
                  {/* <div className="md:w-[50%] md:mb-0">
                    <Tab isDisabled />
                  </div> */}
                </div>
                <div className="md:w-[70%]  md:h-[78%] md:p-[10px_0_0_0]">
                  <div className="overflow-scroll p-2 md:p-0  md:h-[80%]">
                    <CommonDropDown
                      options={options.option1}
                      selected={annualIncome}
                      setSelected={setAnnaulIncome}
                      label={"Annual Income"}
                    />
                    <CommonDropDown
                      options={options.option2}
                      selected={occupation}
                      setSelected={setOccupation}
                      label={"Occupation"}
                    />
                    {occupation === "Other" && (
                      <TextField
                        fullWidth
                        size="small"
                        sx={{ marginTop: 1, marginBottom: 1 }}
                        placeholder="Please specify other"
                        onChange={(e) => setOccupationOther(e.target.value)}
                      />
                    )}
                    <CommonDropDown
                      options={options.option3}
                      selected={organization}
                      setSelected={setOrganization}
                      label={"Organization Type"}
                    />
                    {organization === "Other" && (
                      <TextField
                        fullWidth
                        size="small"
                        sx={{ marginTop: 1, marginBottom: 1 }}
                        placeholder="Please specify other"
                        onChange={(e) => setOrganizationOther(e.target.value)}
                      />
                    )}
                    <CommonDropDown
                      options={options.option4}
                      selected={political}
                      setSelected={setPolitical}
                      label={"Are you a Politically Exposed Person(PEP) or family member/ close relative / associate of PEP"}
                    />
                    <p className="text-red-500 text-sm" id="incomeError">
                      {error && error}
                    </p>
                    <i
                      className="text-xs font-medium text-black"
                    >
                      "Politically Exposed Persons" (PEPs) are individuals who have been entrusted with prominent public functions by a foreign country, including the heads of States or Governments, senior politicians, senior government or judicial or military officers, senior executives of state-owned corporations and important political party officials.
                    </i>
                    {/* <div className="h-1/3"></div> */}
                  </div>
                  <div className="w-full mt-7 md:w-[235px] md:mt-[2px] mb-4 md:mb-0 md:h-[20%]">
                    <CommonButton
                      label="Proceed"
                      filled
                      arrow
                      id={"incomeProcess"}
                      onClickHandler={onClickHandler}
                    />
                  </div>
                </div>
              </div>
            </BottomCard>
          </MsHome>
        </div>
      )}
    </>
  );
};

export default AnnualIncome;
