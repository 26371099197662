import { Button } from "@mui/material";
import React from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { ReactComponent as InfoIcon } from '../../../info-icon.svg';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { useState } from "react";
import { useEffect } from "react";

const CorporateUpload = ({
  title = "Default Title",
  supportedFile = "Formats",
  onClickHandler = () => { },
}) => {
  const tooltipTitle = ["List of Directors", "List of Partners", "List of Trustees"];

  const [tooltipText, setTooltipText] = useState("")

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.common.white,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "#1E1E1E",
      padding: "16px 12px",
      border: 1,
      borderColor: 'grey.500'
    },
  }));

  useEffect(() => {
    if (title == "List of Directors") {
      setTooltipText("Kindly upload the list of names for all Directors and relevant members holding senior management positions.")
    } else if (title == "List of Partners") {
      setTooltipText("Kindly upload the names of all Partners.")
    } else if (title == "List of Trustees") {
      setTooltipText("Kindly upload  the names of all trustees, beneficiaries, settlors, and authors of the trust. List of Trustees and documents as required for   individuals for those discharging roles as trustees and authorised to transact on behalf of the trust.")
    }
  }, [])

  return (
    <div className="border-dashed border-2 border-gray-300 p-4 md:w-[85%] w-full mt-2">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2 ">
          <FileUploadIcon />
          <div className="flex flex-col">
            {tooltipTitle.includes(title) ? <div className="flex flex-row items-center">
              <p className="font-bold text-sm mr-2">{title}  </p>

              <BootstrapTooltip title={tooltipText}>
                <InfoIcon ></InfoIcon>
              </BootstrapTooltip>

            </div> : <p className="font-bold mt-2 text-sm">{title}</p>
            }
            <p className="text-xs mb-1">{supportedFile}</p>
          </div>
        </div>
        <div className="flex items-center justify-between gap-10">
          <Button
            variant="contained"
            onClick={onClickHandler}
            sx={{
              ":hover": {
                bgcolor: "white",
                boxShadow: "none",
              },
              background: "white",
              color: "#E94D51",
              border: "1px solid #E94D51",
              boxShadow: "none",
              width: "60px",
              fontSize: "11px",
            }}
            component="label"
          >
            Upload
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CorporateUpload;
