// import { createSlice } from "@reduxjs/toolkit";

// export const adhaarRedirectInfo = createSlice({
//     name: "AdhaarRedirectInfo",

//     initialState: {
//         requestID: "",
//     },
//     reducers: {
//         addRequestID: (state, action) => {
//             state.requestID = action.payload;
//         },
//     },
// });

// // Action creators are generated for each case reducer function
// export const { addRequestID } = adhaarRedirectInfo.actions;

// export default adhaarRedirectInfo.reducer;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userImage: "",
  userImageName: "",
  selfieChangeUrl: false,
}
export const cameraSlice = createSlice({
  name: "CameraImage",

  initialState,

  reducers: {
    addUserImage: (state, action) => {
      state.userImage = action.payload;
    },
    addUserImageName: (state, action) => {
      state.userImageName = action.payload;
    },
    changeSelfieUrl: (state, action) => {
      state.selfieChangeUrl = action.payload
    },
    clearUserImageDetails: (state) => initialState
  },
});

export const { addUserImage, addUserImageName, changeSelfieUrl, clearUserImageDetails } = cameraSlice.actions;
export default cameraSlice.reducer;
