import { apiInstance } from "./networking";

export const coPanService = async (payload) => {
  const { data = {} } = await apiInstance.post(`primary/corp-ckyc-details`, payload);
console.log("coPanService DATA",data)
  return data;
};

export const coCinService = async (payload) => {
  const {data = {}} = await apiInstance.post(`primary/cin-details`,payload)
  return data
}

export const coGstService = async (payload) => {
  console.log("payload for api call",payload)
  const {data = {}} = await apiInstance.post(`primary/gst-details`,payload)
  return data
}
