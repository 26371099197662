import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store, { persistor } from "./Screens/Redux/store";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
// import HDFCERGOAPP from "./Router";
import HDFCERGOAPP from "./Router";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter } from "react-router-dom";

// import Steppers from "./Components/kyc-components/Steppers";

// Sentry.init({
//   dsn: "https://8dccf83f298c4d76bc76b976e8953219@o4504439043850240.ingest.sentry.io/4504439055843328",
//   integrations: [new BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <HDFCERGOAPP />
      </PersistGate>
    </Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
