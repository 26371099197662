import { createSlice } from "@reduxjs/toolkit";

export const toggleSlice = createSlice({
  name: "switchButton",

  initialState: {
    activeSwitch: true,
  },
  reducers: {
    toggleButton: (state, action) => {
      state.activeSwitch = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { toggleButton } = toggleSlice.actions;

export default toggleSlice.reducer;
