import { validatePan } from "../../../../services/pan-service";

const axios = require("axios");

//
export async function generateDigilockerURL(data) {
  const config = {
    method: "post",
    url: `${process.env.REACT_APP_BASE_URL}/primary/generate-digilocker-url`,
    headers: {
      txnid: localStorage.getItem("txnId"),
    },
    data,
  };

  let response = await axios(config);

  if (response.data.success) {
    return response.data.data;
  }

  return false;
}

export async function fetchPanCardInfoInterface(panCardNumber, kyc_id , txn_id , doc_type,channel) {
  const data = JSON.stringify({
    pan: String(panCardNumber).toUpperCase(),
    kyc_id,
    txn_id,
    doc_type,
    channel,
  });

  let response = await validatePan(data).catch((err) => {
    return {
      success: false,
      message: err?.response?.data?.message || "Something went wrong",
    };
  });

  if (response?.success === false) {
    return response;
  }
  return response;
}
