import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CommonButton from "../CommonButton/CommonButton";
import styles from "./CommonSuccess.module.css";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import copy from "copy-to-clipboard";
import MsHome from "../../Maruti-Suzuki-components/MsHome";
import axios from "axios";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { gaFunction } from "../../../Router";

const CommonSuccess = () => {
  const { channelUrl } = useSelector(
    (state) => state.partnerChannelNameSlice.marutiUserDetails
  );
  console.log({channelUrl})
  const [timer, setTimer] = useState(5);
  const [isCopied, setIsCopied] = useState("");
  const { state } = useLocation();
  const { kyc_ref_id, successUrl, typeOfKyc } = state || {};
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // BELOW FUNCTION & USE EFFCET IS USED FOR REDIRECTION MARUTI PORTAL
  const timeOutCallback = useCallback(
    () => setTimer((currTimer) => currTimer - 1),
    []
  );
  useEffect(() => {
    let resendOTPTimer;
    if (timer >= 1) {
      resendOTPTimer = setTimeout(timeOutCallback, 1000);
    }
    else if(channelUrl==="maruti" && transactionId.slice(0,3)==="KY3" ) {
      redirectToMaruti()
    }
    return () => {
      clearTimeout(resendOTPTimer);
    };
  }, [timer, timeOutCallback]);

  const redirectHandler = () => {
    navigate("/", { replace: true });
    dispatch({ type: "USER_LOGOUT" });
    localStorage.clear();
  };

  const copyIdHandler = () => {
    copy(kyc_ref_id);
    setIsCopied("You have copied KYC Id ");
    setTimeout(() => {
      setIsCopied("");
    }, 2000);
  };

  let redirectURL = String(localStorage.getItem("redirectUrl"));
  // let redirectURLmaruti = "https://marutiinsurancenew-staging.azurewebsites.net/KycIdResponsePushBank.aspx?kui={kycuiqueidentifier}"
  let transactionId = String(localStorage.getItem("txnId"));
  let callbackUrl = String(localStorage.getItem("callbackUrl"));
  //clear localStorage

  // localStorage.removeItem("token");

  const performCallbackPost = useCallback(async () => {
    // perform callback post
    let kycDataToPost = {
      status: typeOfKyc === "ovd" || typeOfKyc === "pending" || typeOfKyc === "unassigned" || typeOfKyc === "assigned" ? "pending" : "approved",
      kyc_id: kyc_ref_id, //should return kyc_ref_id in all cases
      txnId: transactionId,
    };

    kycDataToPost = JSON.stringify(kycDataToPost);

    const config = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: kycDataToPost,
    };
    if (callbackUrl !== "null" && callbackUrl !== "undefined") {
      fetch(callbackUrl, config)
        .then((response) => response.json())
        .then((data) => console.log(data))
        .catch((err) => console.log(err));
      return;
    }
  }, [callbackUrl, kyc_ref_id, transactionId, typeOfKyc]);

  const urlQueryHelper = (url, queryParam, queryParamValue) => {
    if (url && url.indexOf("?") >= 0 && queryParamValue) {
      url = `${url}&${queryParam}=${encodeURIComponent(queryParamValue)}`;
      return url;
    }
    if (queryParamValue) {
      url = `${url}?${queryParam}=${encodeURIComponent(queryParamValue)}`;
    }
    return url;
  };

  const redirectToMaruti=()=>{ 
    window.location = `${process.env.REACT_APP_MARUTI_LINK}?kui=${transactionId}`;
    localStorage.clear();
  }

  useEffect(() => {
    performCallbackPost().then(() => {
      if (redirectURL !== "null" && redirectURL !== "undefined") {
        setTimeout(() => {
          let filteredRedirecturl = urlQueryHelper(
            redirectURL,
            "txnId",
            transactionId
          );
          filteredRedirecturl = urlQueryHelper(
            filteredRedirecturl,
            "status",
            typeOfKyc === "ovd" || typeOfKyc === "pending"  || typeOfKyc === "unassigned" || typeOfKyc === "assigned" ? "pending" : "approved"
          );
          filteredRedirecturl = urlQueryHelper(
            filteredRedirecturl,
            "kyc_id",
            kyc_ref_id
          );
          console.log({filteredRedirecturl})
          if (typeOfKyc === "ovd")
            return (window.location = filteredRedirecturl);
          window.location = filteredRedirecturl;
        }, 2000);
      }
    });
  }, [kyc_ref_id, performCallbackPost, redirectURL, transactionId, typeOfKyc]);

  useEffect(() => {
    if (successUrl && kyc_ref_id) {
      setTimeout(() => {
        let url = `${successUrl}?kyc_id=${kyc_ref_id}`;
        window.location.replace(encodeURI(url));
      }, 3000);
    }
  }, [kyc_ref_id, successUrl]);

  useEffect(() => {
    // disable browser button
    return () => {
      setTimeout(() => {
        window.history.forward();
      }, 0);
    };
  }, []);

  useEffect(() => {
    gaFunction("Kyc Completed", "Kyc Completed Successfully");
  }, []);

  useEffect(() => {
    if(channelUrl!=="maruti"){
      dispatch({ type: "USER_LOGOUT" });
      localStorage.clear();
    }
  }, []);

  return (
    <>
      <div className={styles.mainContainer + "relative md:block md:h-full"}>
        <MsHome>
          <div className="md:w-[70%] h-full ">
            <div className="p-[16px]  sm:hidden ">
              <img
                src="/assets/hdfc-ergo-logo.webp"
                alt="HDFC-ERGO-logo"
                className={styles["logo"]}
              />
            </div>
            <div className="h-[45%]">
              {channelUrl === "maruti" ? (
                <lottie-player
                  src="/assets/lottieFiles/success_blue.json"
                  background="transparent"
                  speed="1.5"
                  style={{ width: "299px", height: "220", margin: "auto" }}
                  autoplay
                ></lottie-player>
              ) : (
                <lottie-player
                  src="/assets/lottieFiles/success.json"
                  background="transparent"
                  speed="1.5"
                  style={{ width: "299px", height: "220", margin: "auto" }}
                  autoplay
                ></lottie-player>
              )}
            </div>
            <div className="h-[55%]">
              <div className={styles.innerContent}>
                <div className={styles.innerContent1}>Thank You!</div>
                <div className={styles.innerContent2}>
                  {`Your KYC is ${
                    typeOfKyc === "ovd" ||  typeOfKyc === "pending" || typeOfKyc === "unassigned" || typeOfKyc === "assigned"  ? "submitted" : "completed"
                  } successfully`}
                </div>
              </div>
              <div className={`${styles.outerContent} `}>
                {`Pehchaan KYC ID : ${kyc_ref_id}`}
                <ContentCopyIcon onClick={copyIdHandler} />
              </div>
              <div className="p-5 md:p-0 text-sm md:text-center md:mt-[5px]">
                <p className="w-[100%] ml-auto mr-auto text-gray-500 text-center mb-4">
                  {isCopied && <span className="text-center">{isCopied}</span>}
                </p>
                <p>Kindly save the above KYC ID number for future reference</p>
                <p className="text-gray-500 text-xs flex mt-[4px] justify-center">
                  Note: This Pehchaan KYC ID is for internal usage at HDFC ERGO
                </p>
                {/* <span className="text-sm">For queries contact : &nbsp;</span>
                <a
                  href="mailto: ekyc.support@hdfcergo.com"
                  className="text-blue-500 text-sm"
                >
                  ekyc.support@hdfcergo.com
                </a> */}
              </div>
              {/* REDIRECT BUTTON */}
              {channelUrl==="maruti" && transactionId.slice(0,3)==='KY3' ? (
                <div className="w-full mt-1 p-[16px] md:p-0  md:mb-4 md:w-[30%] md:ml-auto md:mr-auto">
                  <CommonButton
                    center
                    label={`Redirect in ${timer}...`}
                    filled
                    id={"success"}
                    onClickHandler={redirectToMaruti}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
            {/* <div className="sm:hidden h-1/3"></div> */}

            <div className="text-center sm:invisible">
              <p>
                <span className="opacity-50">Powered by</span> &nbsp;
                <span className="drop-shadow-md bg-white pl-1 pr-1 pt-0.5 pb-0.5 rounded-sm font-bold">
                  Ctrl
                </span>
                &nbsp;
                <span className="drop-shadow-md bg-red-500 pl-1 pr-1 pt-0.5 pb-0.5 rounded-sm text-white font-bold">
                  N
                </span>
              </p>
            </div>
          </div>
        </MsHome>
      </div>
    </>
  );
};

export default CommonSuccess;
