import React, { useState } from "react";
// import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
// import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { optionsValue } from "../../Redux/otherdocsDropDownValueSlice";

const useStyles = makeStyles({
  root: {
    "& .MuiSvgIcon-root": {
      color: "white",
    },
    "& .MuiSelect-select": {
      padding: "8px 16px",
    },
  },
});

const Otherdocsdropdown = () => {
  const [dropValue, setDropValue] = useState("aadhaar");
  const classes = useStyles();
  const disptach = useDispatch();

  const handleChange = (event) => {
    setDropValue(event.target.value);
    disptach(optionsValue(event.target.value));
  };

  return (
    <div>
      <FormControl sx={{ m: 0, width: "100%" }} classes={classes}>
        <Select
          sx={{ backgroundColor: "var(--primary-color)", color: "white" }}
          value={dropValue}
          onChange={handleChange}
          defaultValue="aadhaar"
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem value={"aadhaar"} id={"aadhaar"}>
            Aadhaar
          </MenuItem>
          <MenuItem value={"maskedAadhaar"} id={"maskedAadhaar"}>
            Masked Aadhaar
          </MenuItem>
          <MenuItem value={"voterid"} id={"voterid"}>
            Voter ID
          </MenuItem>
          <MenuItem value={"drivingLicence"} id={"drivingLicence"}>
            Driving License
          </MenuItem>
          <MenuItem value={"passport"} id={"passport"}>
            Passport
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default Otherdocsdropdown;
