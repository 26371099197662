import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CommonButton from "../../Components/global-components/CommonButton/CommonButton";
import Loadingoverlay from "../../Components/global-components/LoadingOverlay/Loadingoverlay";
import { getImage } from "../../services/camera-service";
import Header from "../../Components/home-components/Header/Header";
import MsHome from "../../Components/Maruti-Suzuki-components/MsHome";
import { activeStepper } from "../Redux/stepperSlice";
import { gaFunction, OEM_List } from "../../Router";
import axios from "axios";

const ImageScreen = () => {
  const userImage = useSelector((state) => state.CameraImage.userImage);
  let {
    gender,
    maritalStatus,
    mothersName,
    fatherName,
    correspondenceAddress,
    correspondenceState,
    correspondenceCity,
    correspondencePincode,
    userEditedName,
    userEditedAddress,
    permanentAddress,
    permanentCity,
    permanentDistrict,
    permanentCountry,
    permanentState,
    permanentPincode,
    name,
    manualKYC,
  } = useSelector((state) => state.userDetails.customerData);

  const userPanNumber = useSelector((state) => state.panDetails.userPanNumber);
  const { mi_u, channelUrl,subChannel } = useSelector(
    (state) => state.partnerChannelNameSlice.marutiUserDetails
  );
  const kycId = useSelector((state) => state.kycDetails.kycRefIdAddNewKyc);
  const { dob, fatherName: fatherNameOVD } = useSelector(
    (state) => state.uploadedFile.previewDocumentDetail
  );
  const kycRefId = useSelector((state) => state.uploadedFile.kycRefId);
  const selectedDoctype = useSelector(
    (state) => state.dropDownValue.selectedValue
  );

  const frontFileName = useSelector(
    (state) => state.uploadedFile.documentImage.frontFile
  );

  const isSelfieUrlChange = useSelector(
    (state) => state.CameraImage.selfieChangeUrl
  );
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { isRouteAllowed } = state || {};

  const [image] = useState(null);

  const retakeHandler = () => {
    navigate("/webcam", {
      state: { isRouteAllowed: true },
    });
  };
  const skipDueDeligence = () => {
    let data = JSON.stringify({
      // mobile: phoneNumber,
      pan: userPanNumber,
      // annualIncome: annualIncome,
      // occupation:
      //   occupation === "Other"
      //     ? `${occupation} - ${occupationOther}`
      //     : occupation,
      // organizationType:
      //   organization === "Other"
      //     ? `${organization} - ${organizationOther}`
      //     : organization,
      // isPoliticallyExposed:
      //   political === "Yes" || political === "Related to one"
      //     ? true
      //     : false,
      gender: gender,
      maritalStatus: maritalStatus,
      mothersName: mothersName,
      correspondenceAddress: correspondenceAddress,
      correspondenceState: correspondenceState,
      correspondenceCity: correspondenceCity,
      correspondencePincode: correspondencePincode,
      type: selectedDoctype === "maskedAadhaar" ? "aadhaar" : selectedDoctype,
      permanentCity,
      permanentDistrict,
      permanentCountry,
      permanentState,
      permanentPincode,
      permanentAddress,
      name,
      // mi_identifier: mi_u,
      channel: channelUrl,
      subChannel,
      txnId: mi_u,
      kycId,
      dob,
      fatherName: fatherName || fatherNameOVD || "",
      userEditedName,
      userEditedAddress,
      manualKYC,
    });
    // https://ctrln-uat.hdfcergo.com
    let config = {
      method: "patch",
      url: `${process.env.REACT_APP_BASE_URL}/customer/customer-kyc`,
      headers: {
        "Content-Type": "application/json",
        txnId: localStorage.getItem("txnId") === "null" ?? null,
        // token: localStorage.getItem("token"),
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          dispatch(activeStepper(3));
          navigate("/Success", {
            state: {
              kyc_ref_id: response?.data?.data?.kyc_id,
              successUrl: response?.data?.data?.success_url,
              typeOfKyc: "ovd",
            },
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  //   let fileBase64Array = [userImage];

  let convertedFiles = async () => {
    const res = await fetch(userImage);
    const buf = await res.arrayBuffer(res);
    return new File([buf], `${new Date().valueOf()}.jpeg`, {
      type: "image/jpeg",
    });
  };

  const submitHandler = () => {
    gaFunction("Selfie", "Selfie Submitted");
    setIsLoading((prev) => !prev);
    if (!isSelfieUrlChange) {
      if (OEM_List.includes(channelUrl)) {
        skipDueDeligence();
      } else {
        navigate("/income", {
          state: {
            isRouteAllowed: true,
            type:
              selectedDoctype === "maskedAadhaar" ? "aadhaar" : selectedDoctype,
            typeOfDoc: "ovd",
          },
        });
      }
    } else {
      let FormData = require("form-data");
      let data = new FormData();
      convertedFiles().then((file) => {
        data.append("front", file);
        data.append("kycRefId", kycRefId);
        getImage(data)
          .then(function (response) {
            if (response.success === true) {
              dispatch(activeStepper(2));
              setIsLoading((prev) => !prev);
              if (OEM_List.includes(channelUrl)) {
                skipDueDeligence();
              } else {
                navigate("/income", {
                  state: {
                    kyc_ref_id: response?.data?.kycRefId,
                    isRouteAllowed: true,
                    type:
                      selectedDoctype === "maskedAadhaar"
                        ? "aadhaar"
                        : selectedDoctype,
                    typeOfDoc: "ovd",
                  },
                });
              }
              // navigate("/success", {
              //   state: { kyc_ref_id: response?.data?.kycRefId },
              // });
            }
          })
          .catch(function (error) {
            console.log(error);
            setIsLoading(false);
          });
      });
    }
  };
  useEffect(() => {
    if (!state) navigate("/");
  }, [navigate, state]);
  useEffect(() => {
    // disable browser button
    return () => {
      setTimeout(() => {
        window.history.forward();
      }, 0);
    };
  }, []);

  useEffect(() => {
    if (!frontFileName?.length) navigate("/kyc");
  }, [frontFileName?.length]);

  return (
    <>
      {isRouteAllowed && (
        <div className="md:h-full">
          <MsHome>
            {isLoading ? (
              <Loadingoverlay />
            ) : (
              <>
                <div className="m-4 ml-5 h-full">
                  <div className="hidden md:block md:h-[17%]">
                    <h1>
                      <b>Capture Image</b>
                    </h1>
                    <p>
                      Kindly ensure that your face is clearly visible and in the
                      center of the image.
                    </p>
                  </div>
                  <div className=" md:p-4 md:flex md:rounded-lg md:h-[60%] md:max-w-[100%] justify-between flex-col">
                    <img
                      src={userImage}
                      alt="userImage"
                      className="h-full w-full md:w-fit md:rounded-lg"
                    />

                    <div className="md:flex md:justify-between h-full  md:h-[45px] md:gap-[10px]  mt-5">
                      <CommonButton
                        label="Retake"
                        center
                        onClickHandler={retakeHandler}
                      />
                      <div className="p-2 md:p-0" />
                      <CommonButton
                        center
                        filled
                        label="Submit"
                        onClickHandler={submitHandler}
                      />
                      {/* <img src={image} alt="ale" height={300}></img> */}
                    </div>
                  </div>
                </div>
                <div className="text-center md:absolute md:bottom-4 md:left-0 md:right-0  md:hidden">
                  <p>
                    <span className="opacity-50">Powered by</span> &nbsp;
                    <span className="drop-shadow-md bg-white pl-1 pr-1 pt-0.5 pb-0.5 rounded-sm font-bold">
                      Ctrl
                    </span>
                    &nbsp;
                    <span className="drop-shadow-md bg-red-500 pl-1 pr-1 pt-0.5 pb-0.5 rounded-sm text-white font-bold">
                      N
                    </span>
                  </p>
                </div>
              </>
            )}
          </MsHome>
        </div>
      )}
    </>
  );
};

export default ImageScreen;
