import { createSlice } from "@reduxjs/toolkit";

export const kycDetailsSlice = createSlice({
  name: "kycDetails",

  initialState: {
    kycRecords: {
      data: "",
    },
    kycRefId: null,
    kycRefIdAddNewKyc: null,
  },
  reducers: {
    kycRecords: (state, action) => {
      state.kycRecords = action.payload;
    },
    kycRefId: (state, action) => {
      state.kycRefId = action.payload;
    },
    kycRefIdAddNewKyc: (state, action) => {
      state.kycRefIdAddNewKyc = action.payload;
    },
  },
});

export const { kycRecords, kycRefId, kycRefIdAddNewKyc } =
  kycDetailsSlice.actions;

export default kycDetailsSlice.reducer;
