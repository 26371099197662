import { fetchShortDetailsForPartnerDigilocker, requestDigilockerURLForPartnerRedirect } from "../../services/adhaar-digilocker-service";

export async function requestDigilockerLinkWithRequestId(requestID, authToken) {
  let response = await requestDigilockerURLForPartnerRedirect(
    requestID,
    authToken
  );

  if (response?.success) {
    return response.data;
  }

  return false;
}

export async function getShortDetailsOfDigilockerKYCWithRequestID(
  requestId,
  authToken
) {
  let response = await fetchShortDetailsForPartnerDigilocker(
    requestId,
    authToken
  );

  if (response?.success) {
    return response.data;
  }

  return false;

}
