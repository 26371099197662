import React, { useEffect, useState } from "react";
import BannerImage from "../../../Components/login-components/banner-image/BannerImage";
import BottomCard from "../../../Components/login-components/bottom-card/BottomCard";
import LogoContainer from "../../../Components/login-components/logo-container/LogoContainer";
import { BiUser } from "react-icons/bi";
import { MdOutlineLocationOn } from "react-icons/md";
import CommonButton from "../../../Components/global-components/CommonButton/CommonButton";
import { useLocation, useNavigate } from "react-router-dom";
import CommonDrawer from "../../../Components/global-components/CommonDrawer/CommonDrawer";
import { useDispatch, useSelector } from "react-redux";
import Tab from "../../../Components/kyc-components/Tab";
import TextInput from "../../../Components/global-components/CommonTextInput/TextInput";
import CommonDropDown from "../../../Components/global-components/CommonDropDown/CommonDropDown";
import { customerData } from "../../Redux/userData";
import MsHome from "../../../Components/Maruti-Suzuki-components/MsHome";
import { activeStepper } from "../../Redux/stepperSlice";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { toggleTabs } from "../../Redux/slice";
import { formValidator, gaFunction, OEM_List } from "../../../Router";
import { showGlobalError } from "../../Redux/globalErrorSlice";
import axios from "axios";

const gender = ["Gender", "Male", "Female", "Others"];
const maritalStatus = ["Marital Status", "Single", "Married"];

const AdhaarCardFetchDetailsFrame = () => {

  console.log("--------AdhaarCardFetchDetailsFrame---------")

  const aadhaarDigiLockerGender = useSelector(
    (state) => state.AdhaarRedirectInfo.aadhaarDigiLockerGender
  );

  const aadhaarDigiLockerFatherName = useSelector(
    (state) => state.AdhaarRedirectInfo.aadhaarDigiLockerFatherName
  );
  const aadhaarDigiLockerAddress = useSelector(
    (state) => state.AdhaarRedirectInfo.aadhaarDigiLockerAddress
  );

  const aadhaarDigiLockerUserName = useSelector(
    (state) => state.AdhaarRedirectInfo.aadhaarDigiLockerUserName
  );

  const aadhaarDigiLockerDob = useSelector(
    (state) => state.AdhaarRedirectInfo.aadhaarDigiLockerDob
  );

  let {
    permanentAddress,
    permanentCity,
    permanentDistrict,
    permanentCountry,
    permanentState,
    permanentPincode,
  } = useSelector((state) => state.userDetails.customerData);
  console.log(
    "scar logs OBJ",
    {aadhaarDigiLockerAddress,
    permanentAddress,
    permanentCity,
    permanentDistrict,
    permanentCountry,
    permanentState,
    permanentPincode,}
  )
  const userPanNumber = useSelector((state) => state.panDetails.userPanNumber);
  const { mi_u, channelUrl,subChannel } = useSelector(
    (state) => state.partnerChannelNameSlice.marutiUserDetails
  );
  const kycId = useSelector((state) => state.kycDetails.kycRefIdAddNewKyc);
  const { dob } = useSelector(
    (state) => state.uploadedFile.previewDocumentDetail
  );

  const switchGendervalue = (val) => {
    switch (val) {
      case "M":
        return "Male";

      case "F":
        return "Female";

      case "O":
        return "Others";

      case "T":
        return "Other";

      default:
        return "";
    }
  };

  const [openDrawer, setOpenDrawer] = useState(false);
  const [addressActive, setAddressActive] = useState(false);
  const [checkboxActive, setCheckboxActive] = useState(true);
  const [showBottomButtons, setShowBottomButtons] = useState(true);
  const [motherName, setMotherName] = useState("");
  const [error, setError] = useState("");
  const [coError, setCoError] = useState("");
  const [genderValue, setGenderValue] = useState(
    switchGendervalue(aadhaarDigiLockerGender) || ""
  );
  const [maritalValue, setMaritalValue] = useState("");
  const [coAddress, setCoAddress] = useState("");
  const [coState, setCoState] = useState("");
  const [coCity, setCoCity] = useState("");
  const [coPinCode, setCoPinCode] = useState("");
  const [IsCheckbox, setIsCheckbox] = useState(false);
  const [fatherName, setfatherName] = useState(
    aadhaarDigiLockerFatherName || ""
  );
  const { state } = useLocation();
  const { isRouteAllowed } = state || {};
  const dispatch = useDispatch();

  let fieldArray = [
    { fieldLength: motherName?.trim()?.length, fieldName: "mother name" },
    { fieldLength: fatherName?.trim()?.length, fieldName: "father name" },
    { fieldLength: maritalValue?.trim()?.length, fieldName: "marital stauts" },
    { fieldLength: genderValue?.trim()?.length, fieldName: "gender" },
  ];

  let correspondenceFieldArray = [
    {
      fieldLength: coAddress?.trim()?.length,
      fieldName: "Correspondence Address",
    },
    { fieldLength: coState?.trim()?.length, fieldName: "Correspondence State" },
    { fieldLength: coCity?.trim()?.length, fieldName: "Correspondence City" },
    {
      fieldLength: coPinCode?.trim()?.length,
      fieldName: "Correspondence Pincode",
    },
  ];

  const pinCodeValidator = (value) => {
    let pinCodeRegex = /^[0-9\b]+$/;
    if (pinCodeRegex.test(value)) {
      setCoPinCode(value);
    }
    if (!pinCodeRegex.test(value)) {
      setCoPinCode("");
    }
  };
  let navigate = useNavigate();

  const AddressHandler = () => {
    setAddressActive(!addressActive);
    setCheckboxActive(!checkboxActive);
  };

  // const backHandler = () => {
  //   navigate("/kyc");
  // };
  const skipDueDeligence = () => {
    let data = JSON.stringify({
      // mobile: phoneNumber,
      pan: userPanNumber,
      // annualIncome: annualIncome,
      // occupation:
      //   occupation === "Other"
      //     ? `${occupation} - ${occupationOther}`
      //     : occupation,
      // organizationType:
      //   organization === "Other"
      //     ? `${organization} - ${organizationOther}`
      //     : organization,
      // isPoliticallyExposed:
      //   political === "Yes" || political === "Related to one"
      //     ? true
      //     : false,
      gender: genderValue,
      maritalStatus: maritalValue,
      mothersName: motherName,
      correspondenceAddress: coAddress,
      correspondenceState: coState,
      correspondenceCity: coCity,
      correspondencePincode: coPinCode,
      type: "aadhaar",
      permanentCity,
      permanentDistrict,
      permanentCountry,
      permanentState,
      permanentPincode,
      permanentAddress,
      name: aadhaarDigiLockerUserName,
      // mi_identifier: mi_u,
      channel: channelUrl,
      subChannel,
      txnId: mi_u,
      kycId,
      dob:aadhaarDigiLockerDob,
      fatherName,
      // manualKYC:aadhaarDigiLockerFatherName !==fatherName
    });
    // https://ctrln-uat.hdfcergo.com
    let config = {
      method: "patch",
      url: `${process.env.REACT_APP_BASE_URL}/customer/customer-kyc`,
      headers: {
        "Content-Type": "application/json",
        txnId: localStorage.getItem("txnId") === "null" ?? null,
        // token: localStorage.getItem("token"),
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          dispatch(activeStepper(3));
          navigate("/Success", {
            state: {
              kyc_ref_id: response?.data?.data?.kyc_id,
              successUrl: response?.data?.data?.success_url,
              typeOfKyc: "aadhaar",
            },
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const kycHandler = () => {
    setOpenDrawer(false);
  };
  const closeDrawer = () => {
    setOpenDrawer(false);
  };

  const yesHandler = () => {
    const emptyFieldName = formValidator(fieldArray);
    const coEmptyFieldName = formValidator(correspondenceFieldArray);

    if (emptyFieldName) {
      setError("Please fill this detail : " + emptyFieldName);
      dispatch(showGlobalError("Please fill this detail : " + emptyFieldName));
      return;
    }
    if (!checkboxActive && coEmptyFieldName) {
      setCoError("Please fill this detail : " + coEmptyFieldName);
      dispatch(
        showGlobalError("Please fill this detail : " + coEmptyFieldName)
      );
      return;
    }

    gaFunction("Adhar", "Adhar Continue Clicked");
    let isInIf = false;
    if (
      checkboxActive &&
      motherName.length !== 0 &&
      fatherName?.length !== 0 &&
      genderValue !== "" &&
      maritalValue !== ""
    ) {
      isInIf = true;
      if (OEM_List.includes(channelUrl)) {
        skipDueDeligence();
      } else {
        navigate("/income", {
          state: {
            isRouteAllowed: true,
            type: "aadhaar",
          },
        });
        dispatch(
          customerData({
            gender: genderValue,
            maritalStatus: maritalValue,
            mothersName: motherName,
            fatherName,
            correspondenceAddress: coAddress,
            correspondenceState: coState,
            correspondenceCity: coCity,
            correspondencePincode: coPinCode,
            dob:aadhaarDigiLockerDob,
            name:aadhaarDigiLockerUserName,
            // manualKYC:aadhaarDigiLockerFatherName !==fatherName
          })
        );
      }
      dispatch(activeStepper(2));
    } else {
      setError("Please fill the all details");
    }
    if (
      !checkboxActive &&
      coAddress.length > 0 &&
      coAddress.length > 0 &&
      coState.length > 0 &&
      coCity.length > 0 &&
      coPinCode.length > 0 &&
      motherName.length !== 0 &&
      fatherName?.length !== 0 &&
      genderValue !== "" &&
      maritalValue !== "" &&
      IsCheckbox
    ) {
      isInIf = true;
      if (OEM_List.includes(channelUrl)) {
        skipDueDeligence();
      } else {
        navigate("/income", {
          state: {
            isRouteAllowed: true,
            type: "aadhaar",
          },
        });
        dispatch(
          customerData({
            gender: genderValue,
            maritalStatus: maritalValue,
            mothersName: motherName,
            fatherName,
            correspondenceAddress: coAddress,
            correspondenceState: coState,
            correspondenceCity: coCity,
            correspondencePincode: coPinCode,
            // manualKYC:aadhaarDigiLockerFatherName !==fatherName
          })
        );
      }
      dispatch(activeStepper(2));
    } else {
      setError("Please provide your Correspondence Address");
    }
    if (!isInIf) {
      alert("Please fill all fields");
    }
  };

  // const onTextInputFocusHndler = () => {
  //   setShowBottomButtons(false);
  // };
  // const onTextInputBlurHndler = () => {
  //   setShowBottomButtons(true);
  // };
  const checkBoxHandler = () => {
    setIsCheckbox((prev) => !prev);
  };
  const noHandler = () => {
    navigate("/kyc");
    dispatch(toggleTabs("aadhaar"));
  };

  useEffect(() => {
    // disable browser button
    return () => {
      setTimeout(() => {
        window.history.forward();
      }, 0);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setOpenDrawer(true);
    }, 1000);
    if (!state) navigate("/login");
  }, [navigate, state]);

  return (
    <>
      {isRouteAllowed && (
        <div className="h-full flex flex-col">
          <MsHome>
            <BannerImage>
              {/* <CommonTopBackButton backHandler={backHandler} /> */}
              <LogoContainer />
            </BannerImage>
            <div className=" h-full">
              <BottomCard max>
                <div className="h-full w-full z-0 last:mb-[16vh] ">
                  <div className="md:h-full">
                    <div className="hidden md:block md:h-[30%]">
                      <div className="p-[10px] md:p-0"></div>
                      <label className="md:text-xl">
                        <b>KYC Document</b>
                      </label>
                      <p className="hidden md:block md:text-sm">
                        let's fetch your kyc details using
                      </p>
                      <div className="p-[10px] md:p-[7px]"></div>
                      <div className="md:w-[50%] md:mb-5">
                        <Tab isDisabled />
                      </div>
                    </div>
                    <div className="md:w-[100%] md:h-[52%]  md:overflow-scroll">
                      <div>
                        <lable>
                          <b>Name</b>
                        </lable>
                        <div className="flex items-center p-2">
                          <div className="px-4">
                            <BiUser size={25} />
                          </div>
                          <div>
                            <span className="capitalize">
                              {aadhaarDigiLockerUserName || "NA"}
                            </span>
                          </div>
                        </div>
                        <label>
                          <b>Permanent Address</b>
                        </label>
                        <div className="flex flex-row justify-start p-2">
                          <div className="flex flex-col px-4">
                            <MdOutlineLocationOn size={25} />
                          </div>
                          <div className="flex flex-col">
                            <p className="capitalize">
                              {aadhaarDigiLockerAddress || "NA"}
                            </p>
                          </div>
                        </div>
                        <div className="flex gap-5">
                          <CommonDropDown
                            options={gender}
                            label="Gender"
                            selected={genderValue}
                            setSelected={setGenderValue}
                          />
                          <CommonDropDown
                            options={maritalStatus}
                            label="Martial Status"
                            selected={maritalValue}
                            setSelected={setMaritalValue}
                          />
                        </div>
                        <div className="mb-4">
                          <lable className="font-bold">Mother Name</lable>
                          <TextInput
                            placeholder="Mother Name"
                            id="mother_name"
                            value={motherName}
                            width={"md:w-[290px]"}
                            onChange={(e) => setMotherName(e.target.value)}
                          />
                        </div>
                          <div className="mb-4">
                            <lable className="font-bold">Father Name</lable>
                            <TextInput
                              placeholder="Father Name"
                              id="father_name"
                              value={fatherName}
                              width={"md:w-[290px]"}
                              onChange={(e) => {
                                setfatherName(e.target.value)
                              }}
                            />
                          </div>
                        <p className="text-red-500 text-sm">{error}</p>
                        {/* <div className="flex gap-2 mb-2">
                          <ErrorOutlineIcon
                            className="text-primaryH"
                            fontSize="small"
                          />
                          <p className="text-xs flex items-center text-primaryH">
                            Add Correspondence address option as requested.
                          </p>
                        </div> */}
                        <div className="flex items-center">
                          <input
                            id="link-checkbox"
                            type="checkbox"
                            value=""
                            checked={checkboxActive}
                            className="w-5 h-5  rounded border-gray-300 text-primaryH ring-0 focus:ring-0"
                            onClick={AddressHandler}
                          />
                          <label
                            htmlFor="link-checkbox"
                            className="ml-2 text-sm font-medium text-black "
                          >
                            Use this as the correspondence address
                          </label>
                        </div>

                        {addressActive && (
                          <div className="main_address_container mt-5">
                            <label className="font-bold">
                              Correspondence Address
                            </label>
                            <div className="text_iput">
                              <TextInput
                                label={"Address"}
                                id="correspondence_address"
                                placeholder={"ex.  ABC Apt, Mumbai-400001"}
                                onChange={(e) => setCoAddress(e.target.value)}
                                value={coAddress}
                              />
                            </div>
                            <div className="flex flex-row justify-between items-center gap-5">
                              <TextInput
                                label={"State"}
                                id="correspondence_state"
                                onChange={(e) => setCoState(e.target.value)}
                                value={coState}
                                placeholder="ex. Maharashtra"
                              />
                              <TextInput
                                label={"City"}
                                id="correspondence_city"
                                onChange={(e) => setCoCity(e.target.value)}
                                value={coCity}
                                placeholder="ex. Pune"
                              />
                            </div>
                            <div className="pincode w-[47%]">
                              <TextInput
                                type="tel"
                                id="correspondence_number"
                                maxlen={"6"}
                                label={"PinCode"}
                                placeholder={"ex.  400001"}
                                onChange={(e) =>
                                  pinCodeValidator(e.target.value)
                                }
                                value={coPinCode}
                              />
                            </div>
                            <p className="text-red-500 text-sm">{coError}</p>
                            <div className="flex items-center mt-2">
                              <input
                                id="link-checkbox"
                                type="checkbox"
                                value=""
                                checked={IsCheckbox}
                                onClick={checkBoxHandler}
                                className="w-5 h-5 rounded border-gray-300 text-red-600 ring-0 focus:ring-0"
                              />
                              <label
                                htmlFor="link-checkbox"
                                className="ml-2 text-sm font-medium text-black mt-2"
                              >
                                I confirm that this address is correct as per my
                                knowledge
                              </label>
                            </div>
                          </div>
                          // <div className="mt-[21px]">
                          //   <AddressInput
                          //     onFocus={onTextInputFocusHndler}
                          //     onBlur={onTextInputBlurHndler}
                          //   />
                          // </div>
                        )}
                        {openDrawer && (
                          <CommonDrawer
                            onClickHandler={closeDrawer}
                            onProceed={kycHandler}
                            onDenied={noHandler}
                          />
                        )}
                      </div>
                    </div>
                    <div className="hidden  md:flex md:gap-7 md:mt-0  md:w-[235px] ">
                      <CommonButton
                        arrow
                        filled
                        onClickHandler={yesHandler}
                        label={"Continue"}
                        id="aadhaar-details-continue-button"
                      />
                      {/* <CommonButton label="Use Another Address" location /> */}
                    </div>
                  </div>
                </div>
              </BottomCard>
            </div>
            {showBottomButtons && <BottomButtons kycHandler={yesHandler} />}

            {/* <div className="fixed bottom-0 p-4 w-full bg-white rounded-sm border shadow-[2px_-4px_16px_-10px_rgba(0,0,0,0.75)] z-10 space-y-3">
        <CommonButton
          label="Continue"
          arrow
          filled
          onClickHandler={kycHandler}
        />
        <CommonButton label="Use Another Address" location />
      </div> */}
          </MsHome>
        </div>
      )}
    </>
  );
};

export default AdhaarCardFetchDetailsFrame;

function BottomButtons({ kycHandler }) {
  return (
    <div className="fixed bottom-0 short:p-2 p-4 w-full bg-white rounded-sm border shadow-[2px_-4px_16px_-10px_rgba(0,0,0,0.75)] z-10 space-y-3 md:hidden">
      <CommonButton label="Continue" arrow filled onClickHandler={kycHandler} />
      {/* <CommonButton label="Use Another Address" location /> */}
    </div>
  );
}
